import React, {useState, useEffect} from 'react'
import {useLocation, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import qs from 'qs'
import {Grid, Title, Table, TextInput, Select, Button, Pagination, Group, ActionIcon, Modal} from "@mantine/core";
import {SET_HISTORY_PAGINATED_RESULT} from "../../../redux/action";
import api from "../../../api";
import _ from "lodash";
import update from "immutability-helper";
import HistoryTableLine from "./partials/HistoryTableLine";
import { FileSpreadsheet } from 'tabler-icons-react';
import ReturnReportForm from "../../forms/ReturnReportForm";
function cleanUpSearch(search = "") {
    if (search.startsWith('?')) {
        return search.substring(1)
    }
    return search
}

const ReturnHistory = () => {
    const [reportModalOpen, setReportModalOpen] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const customers = useSelector(state => state.app.customers)
    const records = useSelector(state => state.app.history)
    const customerOptions = [{value: 'All', label: 'All'}]
    customers.map(cus => {
        customerOptions.push({
            value: _.toString(cus.id),
            label: cus.company
        })
    })
    const location = useLocation()
    const [params, setParams] = useState({
        page: 1,
        search: {
            id: "",
            delivery_number: "",
            order_number: "",
            web_order_number: ""
        },
        filter: {
            customer_id: "All",
            is_blind: "All"
        }
    })
    useEffect(() => {
        const searchWithoutQuestion = cleanUpSearch(location.search)
        if (!_.isEmpty(qs.parse(searchWithoutQuestion))) {
            setParams(qs.parse(searchWithoutQuestion))
        }
        const loadData = async () => {
            const {code, message} = await api.returns.returnList(searchWithoutQuestion)
            if (code === 200) {
                dispatch({
                    type: SET_HISTORY_PAGINATED_RESULT,
                    payload: message
                })
            }
        }
        loadData()
    }, [location.search])
    return (
        <Grid grow={true}>
            <Grid.Col span={12}>
                <Group position='apart'>
                    <Title order={3}>
                        Return History
                    </Title>
                    <ActionIcon color="red" onClick={() => setReportModalOpen(true)}>
                        <FileSpreadsheet />
                    </ActionIcon>
                </Group>
            </Grid.Col>
            <Table>
                <thead>
                <tr>
                    <th>Return ID</th>
                    <th>Account</th>
                    <th>Delivery #</th>
                    <th>Order #</th>
                    <th>Web #</th>
                    <th>Ref 1</th>
                    <th>Ref 2</th>
                    <th>Date</th>
                    <th>Blind</th>
                    <th>Operator</th>
                    <th>Action</th>
                </tr>
                <tr>
                    <th width={160}>
                        {_.isObject(params) && _.isObject(params['search']) ? <TextInput size='xs' value={params['search']['id'] ? params['search']['id'] : ""}
                                   onChange={(e) => {
                                       setParams(update(params, {
                                           search: {id: {$set: e.currentTarget.value}}
                                       }))
                                   }}
                        /> : null}
                    </th>
                    <th width={160}>
                        {_.isObject(params) && _.isObject(params['filter']) ? <Select data={customerOptions}
                                value={params['filter']['customer_id'] ? params['filter']['customer_id'] : 'All'}
                                onChange={(e) => {
                                    setParams(update(params, {
                                        filter: {customer_id: {$set: e}}
                                    }))
                                }}
                                size='xs'/> : null}
                    </th>
                    <th width={160}>
                        {_.isObject(params) && _.isObject(params['search']) ? <TextInput size='xs' value={params['search']['delivery_number'] ? params['search']['delivery_number'] : ""}
                                                                                         onChange={(e) => {
                                                                                             setParams(update(params, {
                                                                                                 search: {delivery_number: {$set: e.currentTarget.value}}
                                                                                             }))
                                                                                         }}
                        /> : null}
                    </th>
                    <th width={160}>
                        {_.isObject(params) && _.isObject(params['search']) ? <TextInput size='xs' value={params['search']['order_number'] ? params['search']['order_number'] : ""}
                                                                                         onChange={(e) => {
                                                                                             setParams(update(params, {
                                                                                                 search: {order_number: {$set: e.currentTarget.value}}
                                                                                             }))
                                                                                         }}
                        /> : null}
                    </th>
                    <th width={160}>
                        {_.isObject(params) && _.isObject(params['search']) ? <TextInput size='xs' value={params['search']['web_order_number'] ? params['search']['web_order_number'] : ""}
                                                                                         onChange={(e) => {
                                                                                             setParams(update(params, {
                                                                                                 search: {web_order_number: {$set: e.currentTarget.value}}
                                                                                             }))
                                                                                         }}
                        /> : null}
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th width={80}>
                        {_.isObject(params) && _.isObject(params['filter']) ? <Select data={[{value: 'All', label: 'All'}, {value: 'Y', label: 'Blind Only'}, {value: 'N', label: 'Non-Blind Only'}]}
                                              value={params['filter']['is_blind'] ? params['filter']['is_blind'] : 'All'}
                                              onChange={(e) => {
                                                  setParams(update(params, {
                                                      filter: {is_blind: {$set: e}}
                                                  }))
                                              }}
                                              size='xs'/> : null}
                    </th>
                    <th></th>
                    <th>
                        <Button size='xs' color='sgl' onClick={() => {
                            navigate(`/history?${qs.stringify(update(params, {page: {$set: 1}}))}`, {
                                replace: false
                            })
                        }}>Search</Button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {records.data.map(r => {
                    return <HistoryTableLine navigate={navigate} line={r} customers={customers} key={r.id} />
                })}
                <tr>
                    <td colSpan={11}>
                        <Pagination color='sgl' page={records.on_page} onChange={(v) => {
                            navigate(`/history?${qs.stringify(update(params, {page: {$set: v}}))}`, {
                                replace: false
                            })
                        }} total={records.total_pages} />
                    </td>
                </tr>
                </tbody>
            </Table>
            <Modal opened={reportModalOpen}
                   closeOnClickOutside={false}
                   title="Generate Return Report"
                   onClose={() => setReportModalOpen(false)}>
                {reportModalOpen && <ReturnReportForm />}
            </Modal>
        </Grid>
    )
}

ReturnHistory.propTypes = {

}

export default ReturnHistory