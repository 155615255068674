import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import {
    Loader,
    Select
} from '@mantine/core'
import {useDebouncedValue} from "@mantine/hooks"
import api from "../../api"
import _ from "lodash";

const ProductSearch = ({customerId, onProductSelected}) => {
    const [searchValue, setSearchValue] = useState("")
    const [debouncedSearch] = useDebouncedValue(searchValue, 500)
    const [serverData, setServerData] = useState([])
    const [tempValue, setTempValue] = useState("")
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const doSearch = async () => {
            setLoading(true)
            setProducts([])
            setServerData([])
            const {code, message} = await api.returns.productSearch(customerId, debouncedSearch)
            const results = []
            if (code === 200) {
                message.map((product, index) => {
                    results.push({
                        value: _.toString(product.id),
                        label: `[Result ${index + 1}] - ${product.sku} (${product.upc}): ${product.name_1}`
                    })
                })
            }
            setServerData(message)
            setProducts(results)
            setLoading(false)
        }
        if (debouncedSearch && debouncedSearch.length > 2 && !debouncedSearch.startsWith("[Result")) {
            doSearch()
        }
    }, [debouncedSearch])
    return <Select
                data={products}
                placeholder="Product search..."
                searchable
                onSearchChange={(value) => {
                    setSearchValue(value)
                }}
                value={tempValue}
                onChange={(value) => {
                    setTempValue(value)
                    const index = _.findIndex(serverData, p => {
                        return _.toString(p.id) === value
                    })
                    if (index > -1) {
                        onProductSelected(serverData[index])
                    }
                }}
                rightSection={loading ? <Loader size={16} /> : null}
            />
}

ProductSearch.propTypes = {
    customerId: PropTypes.string.isRequired,
    onProductSelected: PropTypes.func.isRequired
}

export default ProductSearch