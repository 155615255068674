import React from 'react'
import {
    Outlet, Navigate, useLocation
} from 'react-router-dom'
import { useSelector } from 'react-redux'


function Guest() {
    const location = useLocation()
    const loading = useSelector((state) => {
        return state.app.isLoading
    })
    console.log(loading)
    return <div>
        <Outlet />
    </div>
}

export default Guest