import React, {useState, useEffect} from 'react'
import {Container, Title, Grid, TextInput} from '@mantine/core'
import api from '../../../api'
import update from 'immutability-helper'
import _ from 'lodash'

const SystemSetting = () => {
    const [system, setSystem] = useState({})
    const [baseValue, setBaseValue] = useState({})
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const loadSettings = async () => {
            const {code, message} = await api.admin.loadSystemSettings()
            if (code === 200) {
                setSystem(message)
                setBaseValue(_.cloneDeep(message))
            }
            setLoading(false)
        }
        loadSettings()
    }, [])
    return (
        <Container>
            <Grid>
                {loading ? null : <Grid.Col span={4}>
                    <Title order={3}>System Settings</Title>
                    <TextInput label="Biztalk FTP Username"
                               mt='lg'
                               value={system["BIZTALK_FTP_USERNAME"] ? system["BIZTALK_FTP_USERNAME"] : ""}
                               onChange={(event) => {
                                   setSystem(update(system, {
                                       BIZTALK_FTP_USERNAME: {$set: event.currentTarget.value}
                                   }))
                               }}
                               onBlur={async () => {
                                   if (system['BIZTALK_FTP_USERNAME'] && system['BIZTALK_FTP_USERNAME'] !== baseValue['BIZTALK_FTP_USERNAME']) {
                                       const {code} = await api.admin.updateOneSystemSettings('BIZTALK_FTP_USERNAME', system['BIZTALK_FTP_USERNAME'])
                                       if (code === 200) {
                                           setBaseValue(update(system, {
                                               BIZTALK_FTP_USERNAME: {$set: system['BIZTALK_FTP_USERNAME']}
                                           }))
                                       }
                                   }
                               }}
                    />
                    <TextInput label="Biztalk FTP Password"
                               mt='lg'
                               value={system["BIZTALK_FTP_PASSWORD"] ? system["BIZTALK_FTP_PASSWORD"] : ""}
                               onChange={(event) => {
                                   setSystem(update(system, {
                                       BIZTALK_FTP_PASSWORD: {$set: event.currentTarget.value}
                                   }))
                               }}
                               onBlur={async () => {
                                   if (system['BIZTALK_FTP_PASSWORD'] && system['BIZTALK_FTP_PASSWORD'] !== baseValue['BIZTALK_FTP_PASSWORD']) {
                                       const {code} = await api.admin.updateOneSystemSettings('BIZTALK_FTP_PASSWORD', system['BIZTALK_FTP_PASSWORD'])
                                       if (code === 200) {
                                           setBaseValue(update(system, {
                                               BIZTALK_FTP_PASSWORD: {$set: system['BIZTALK_FTP_PASSWORD']}
                                           }))
                                       }
                                   }
                               }}
                    />
                    <TextInput label="Biztalk FTP URL"
                               mt='lg'
                               value={system["BIZTALK_FTP_URL"] ? system["BIZTALK_FTP_URL"] : ""}
                               onChange={(event) => {
                                   setSystem(update(system, {
                                       BIZTALK_FTP_URL: {$set: event.currentTarget.value}
                                   }))
                               }}
                               onBlur={async () => {
                                   if (system['BIZTALK_FTP_URL'] && system['BIZTALK_FTP_URL'] !== baseValue['BIZTALK_FTP_URL']) {
                                       const {code} = await api.admin.updateOneSystemSettings('BIZTALK_FTP_URL', system['BIZTALK_FTP_URL'])
                                       if (code === 200) {
                                           setBaseValue(update(system, {
                                               BIZTALK_FTP_URL: {$set: system['BIZTALK_FTP_URL']}
                                           }))
                                       }
                                   }
                               }}
                    />
                    <TextInput label="Biztalk FTP Inbound Directory"
                               mt='lg'
                               value={system["BIZTALK_INBOUND_DIR"] ? system["BIZTALK_INBOUND_DIR"] : ""}
                               onChange={(event) => {
                                   setSystem(update(system, {
                                       BIZTALK_INBOUND_DIR: {$set: event.currentTarget.value}
                                   }))
                               }}
                               onBlur={async () => {
                                   if (system['BIZTALK_INBOUND_DIR'] && system['BIZTALK_INBOUND_DIR'] !== baseValue['BIZTALK_INBOUND_DIR']) {
                                       const {code} = await api.admin.updateOneSystemSettings('BIZTALK_INBOUND_DIR', system['BIZTALK_INBOUND_DIR'])
                                       if (code === 200) {
                                           setBaseValue(update(system, {
                                               BIZTALK_INBOUND_DIR: {$set: system['BIZTALK_INBOUND_DIR']}
                                           }))
                                       }
                                   }
                               }}
                    />
                    <TextInput label="Biztalk FTP Outbound Directory"
                               mt='lg'
                               value={system["BIZTALK_OUTBOUND_DIR"] ? system["BIZTALK_OUTBOUND_DIR"] : ""}
                               onChange={(event) => {
                                   setSystem(update(system, {
                                       BIZTALK_OUTBOUND_DIR: {$set: event.currentTarget.value}
                                   }))
                               }}
                               onBlur={async () => {
                                   if (system['BIZTALK_OUTBOUND_DIR'] && system['BIZTALK_OUTBOUND_DIR'] !== baseValue['BIZTALK_OUTBOUND_DIR']) {
                                       const {code} = await api.admin.updateOneSystemSettings('BIZTALK_OUTBOUND_DIR', system['BIZTALK_OUTBOUND_DIR'])
                                       if (code === 200) {
                                           setBaseValue(update(system, {
                                               BIZTALK_OUTBOUND_DIR: {$set: system['BIZTALK_OUTBOUND_DIR']}
                                           }))
                                       }
                                   }
                               }}
                    />
                    <TextInput label="Biztalk FTP Message Number"
                               mt='lg'
                               value={system["BIZTALK_MESSAGE_ID_COUNTER"] ? system["BIZTALK_MESSAGE_ID_COUNTER"] : ""}
                               onChange={(event) => {
                                   setSystem(update(system, {
                                       BIZTALK_MESSAGE_ID_COUNTER: {$set: event.currentTarget.value}
                                   }))
                               }}
                               onBlur={async () => {
                                   if (system['BIZTALK_MESSAGE_ID_COUNTER'] && system['BIZTALK_MESSAGE_ID_COUNTER'] !== baseValue['BIZTALK_MESSAGE_ID_COUNTER']) {
                                       const {code} = await api.admin.updateOneSystemSettings('BIZTALK_MESSAGE_ID_COUNTER', system['BIZTALK_MESSAGE_ID_COUNTER'])
                                       if (code === 200) {
                                           setBaseValue(update(system, {
                                               BIZTALK_MESSAGE_ID_COUNTER: {$set: system['BIZTALK_MESSAGE_ID_COUNTER']}
                                           }))
                                       }
                                   }
                               }}
                    />
                    <TextInput label="H2O Custoemr ID"
                               description="Matching ID over write above outbound directory to below"
                               mt='lg'
                               value={system["H2O_CUSTOMER_ID"] ? system["H2O_CUSTOMER_ID"] : ""}
                               onChange={(event) => {
                                   setSystem(update(system, {
                                       H2O_CUSTOMER_ID: {$set: event.currentTarget.value}
                                   }))
                               }}
                               onBlur={async () => {
                                   if (system['H2O_CUSTOMER_ID'] && system['H2O_CUSTOMER_ID'] !== baseValue['H2O_CUSTOMER_ID']) {
                                       const {code} = await api.admin.updateOneSystemSettings('H2O_CUSTOMER_ID', system['H2O_CUSTOMER_ID'])
                                       if (code === 200) {
                                           setBaseValue(update(system, {
                                               H2O_CUSTOMER_ID: {$set: system['H2O_CUSTOMER_ID']}
                                           }))
                                       }
                                   }
                               }}
                    />
                    <TextInput label="H2O Upload Directory"
                               description="Use /Return_ToSGL_H2O or your own directory"
                               mt='lg'
                               value={system["H2O_UPLOAD_DIR"] ? system["H2O_UPLOAD_DIR"] : ""}
                               onChange={(event) => {
                                   setSystem(update(system, {
                                       H2O_UPLOAD_DIR: {$set: event.currentTarget.value}
                                   }))
                               }}
                               onBlur={async () => {
                                   if (system['H2O_UPLOAD_DIR'] && system['H2O_UPLOAD_DIR'] !== baseValue['H2O_UPLOAD_DIR']) {
                                       const {code} = await api.admin.updateOneSystemSettings('H2O_UPLOAD_DIR', system['H2O_UPLOAD_DIR'])
                                       if (code === 200) {
                                           setBaseValue(update(system, {
                                               H2O_UPLOAD_DIR: {$set: system['H2O_UPLOAD_DIR']}
                                           }))
                                       }
                                   }
                               }}
                    />
                </Grid.Col>}
            </Grid>
        </Container>
    )
}

export default SystemSetting