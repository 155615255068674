import React, {useState, useEffect} from 'react'
import {Card, Select, TextInput, Title, LoadingOverlay, Anchor} from '@mantine/core'
import {useSelector} from 'react-redux'
import {useDebouncedValue} from '@mantine/hooks'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import api from '../../../../api'

const OrderSearch = ({listOfOrdersLoaded}) => {
    const [loading, setLoading] = useState(false)
    const customers = useSelector(state => state.app.customers)
    const [customerId, setCustomerId] = useState("")
    const [search, setSearch] = useState("")
    const [debouncedSearch] = useDebouncedValue(search, 500)
    const customerOptions = []
    let canBlindReturn = false
    let orderSource = null
    customers.map(c => {
        customerOptions.push({value: _.toString(c.id), label: c.company})
        if (_.toString(c.id) === customerId) {
            canBlindReturn = c.can_blind
            orderSource = c.order_source
        }
    })
    useEffect(() => {
        const searchApi = async () => {
            setLoading(true)
            const {code, message} = await api.returns.orderSearch(customerId, debouncedSearch)
            if (code === 200) {
                listOfOrdersLoaded(message)
            }
            setLoading(false)
        }
        if (_.toInteger(customerId) > 0 && _.isString(debouncedSearch) && debouncedSearch.length > 2) {
            searchApi()
        }
    }, [customerId, debouncedSearch])

    return (
        <Card>
            <LoadingOverlay visible={loading} />
            <Title order={3}>Order Search</Title>
            <Select
                disabled={loading}
                mt='lg'
                data={customerOptions}
                value={customerId}
                onChange={(v) => {setCustomerId(v)}}
                label="Select Customer" />
            <TextInput value={search}
                       disabled={loading}
                       description={orderSource ? `Source: ${orderSource}` : null}
                       mt='lg'
                       mb='lg'
                       onChange={(e) => {setSearch(e.currentTarget.value)}}
                       label="Search Reference" />

            {canBlindReturn ? <Anchor mt="lg" component={Link} to={`/blind/${customerId}`}>
                Create a blind return
            </Anchor> : null}
        </Card>
    )
}

OrderSearch.propTypes = {
    listOfOrdersLoaded: PropTypes.func.isRequired
}

export default OrderSearch