import React from "react";
import PropTypes from "prop-types";
import {Badge, Button} from "@mantine/core";
import api from "../../../../api";

const TaskTableLine = ({task, setTask}) => {
    return <tr>
        <td>{task.id}</td>
        <td>{task.message_1}</td>
        <td>{task.message_2}</td>
        <td>
            {task.is_running ? <Badge color='red'>Y</Badge> : <Badge color='green'>N</Badge>}
        </td>
        <td>{task.attempts_count}</td>
        <td>
            {task.task_status === 'Pending' ? <Badge color='gray'>Pending</Badge> : null}
            {task.task_status === 'Success' ? <Badge color='green'>Success</Badge> : null}
            {task.task_status === 'Error' ? <Badge color='red'>Error</Badge> : null}
            {task.task_status === 'Log Only' ? <Badge color='teal'>Log</Badge> : null}
            {task.task_status === 'Before Downloading' ? <Badge color='teal'>Downloading</Badge> : null}
        </td>
        <td>{task.task_type}</td>
        <td>{api.helpers.toLocalDateTime(task.created_at)}</td>
        <td>
            <Button
                onClick={() => {setTask(task)}}
                variant="subtle" color="sgl" compact={true}>Detail</Button>
        </td>
    </tr>
}

TaskTableLine.propTypes = {
    setTask: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired
}

export default TaskTableLine