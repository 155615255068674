import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {
    TextInput, Box, Group, Button, Select, Checkbox, NumberInput, Alert, Badge
} from '@mantine/core';
import { useForm } from '@mantine/form';
import api from '../../api'

function isScheduledRetrying(attempts_count = 0, task_status = 'Pending', is_running = false) {
    if (!is_running && attempts_count < 3 && task_status === 'Pending') {
        return true
    }
    return false
}

const TaskForm = ({initialValues, taskUpdated}) => {
    const form = useForm({
        initialValues: initialValues
    })

    const [loading, setLoading] = useState(false)
    return (
        <Box mx="auto">
            <Alert color='blue' title="Tasks">
                Tasks with the following attributes will be retried by the system task monitor automatically
                <ul>
                    <li>
                        The task is <Badge color='red'>not</Badge> currently queued.
                    </li>
                    <li>
                        The task has a status of <Badge color='red'>Pending</Badge>
                    </li>
                    <li>
                        The attempt value is less than <Badge color='red'>3</Badge>
                    </li>
                </ul>
            </Alert>
            {!isScheduledRetrying(initialValues.attempts_count, initialValues.task_status, initialValues.is_running) ? <form onSubmit={form.onSubmit(async (values) => {
                setLoading(true)
                const {code, message} = await api.admin.updateSystemTask(values.id, {
                    message_2: values.message_2,
                    is_running: values.is_running,
                    task_status: values.task_status,
                    attempts_count: values.attempts_count
                })
                if (code === 200) {
                    taskUpdated(message)
                }
                setLoading(false)
            })}>
                <TextInput label="Message 2" placeholder="" mt="lg" {...form.getInputProps('message_2')} />
                <NumberInput label="Attempts" placeholder="" mt="lg" {...form.getInputProps('attempts_count')} min={1} max={4}/>
                <Select data={[
                    {value: 'Pending', label: 'Pending'},
                    {value: 'Success', label: 'Success'},
                    {value: 'Error', label: 'Error'},
                    {value: 'Log Only', label: 'Log'},
                    {value: 'Before Downloading', label: 'Downloading'}
                ]} label="Task Status" mt="lg" {...form.getInputProps('task_status')} />
                <Checkbox label="Is In Queue"
                          mt="lg"
                          {...form.getInputProps('is_running', {type: 'checkbox'})}
                />
                <Group position="left" mt="md">
                    <Button type="submit" color='sgl' loading={loading}>Update</Button>

                </Group>
            </form> : <Alert color='blue' title="Queued" mt='xl'>
                This task is in queue.
            </Alert> }

        </Box>
    )
}

TaskForm.propTypes = {
    taskUpdated: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.number.isRequired
    }).isRequired
}

export default TaskForm