import React from 'react'
import LOGO from '../../../assets/scan_logo_1.png'
import {Anchor, Title} from '@mantine/core'
import ForgetPasswordForm from '../../forms/ForgetPasswordForm'
import {Link} from 'react-router-dom'

const ForgetPassword = () => {
    return (
        <div className="auth-container">
            <div className="auth">
                <div className="auth-brand-logo">
                    <img alt="SGL Logo" src={LOGO} />
                </div>
                <Title order={3}>Forget Password</Title>
                <div className="auth-form">
                    <ForgetPasswordForm />
                </div>
                <div className="auth-link">
                    <Anchor size='sm' component={Link} to="/auth/login">
                        Back to login
                    </Anchor>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword