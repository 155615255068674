import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {
    TextInput, Box, Group, Button, Title, PasswordInput, Checkbox, Select
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import {USER_UPDATED, NEW_USER_CREATED} from '../../redux/action'
import api from '../../api'

const UserForm = ({initialValues, onCloseDraw}) => {
    const dispatch = useDispatch()
    let UserFormSchema
    if (initialValues.id === '_new') {
        UserFormSchema = Yup.object().shape({
            name: Yup.string().required("Name is required"),
            email: Yup.string().email().required("Email is not valid"),
            username: Yup.string().required("Username is required"),
            password: Yup.string().required("New password is required"),
            pass_confirmed: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match')
        })
    } else {
        UserFormSchema = Yup.object().shape({
            name: Yup.string().required("Name is required"),
            email: Yup.string().email().required("Email is not valid")
        })
    }

    const form = useForm({
        initialValues: initialValues,
        schema: yupResolver(UserFormSchema)
    })

    const [loading, setLoading] = useState(false)
    return (
        <Box mx={10}>
            <Title order={3}>User Form {initialValues.id === '_new' ? "(New User)" : `(${initialValues.name})`}</Title>
            <form onSubmit={form.onSubmit(async (values) => {
                setLoading(true)
                let response
                if (values.id === '_new') {
                    response = await api.admin.createUser(values)
                } else {
                    response = await api.admin.updateUser(values.id, values)
                }
                const {code, message} = response
                if (code === 200) {
                    if (values.id === '_new') {
                        dispatch({type: NEW_USER_CREATED, payload: message})
                    } else {
                        dispatch({type: USER_UPDATED, payload: message})
                    }
                    setLoading(false)
                    onCloseDraw()
                } else {
                    setLoading(false)
                }
            })}>
                <TextInput
                    required
                    label="Name"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('name')}
                />
                <TextInput
                    required
                    label="Email"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('email')}
                />
                {initialValues.id === '_new' ? <TextInput
                    required
                    label="Permanent Username"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('username')}
                /> : null}
                {initialValues.id === '_new' ? <PasswordInput
                    required
                    label="Password"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('password')}
                /> : null}
                {initialValues.id === '_new' ? <PasswordInput
                    required
                    label="Confirm Password"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('pass_confirmed')}
                /> : null}
                <Checkbox label="This is an admin user"
                          mt="lg"
                    {...form.getInputProps('is_su', {type: 'checkbox'})}
                />
                <Select mt='lg'
                        data={[{ value: 'Active', label: 'Active' },{ value: 'Disabled', label: 'Disabled' }]}
                        label="Account Status"
                        {...form.getInputProps('user_status')}
                />
                <Group position="left" mt="md">
                    <Button type="submit" color='sgl' loading={loading}>Save</Button>
                </Group>
            </form>
        </Box>
    )
}

UserForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    onCloseDraw: PropTypes.func.isRequired
}

export default UserForm