import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {CURRENT_RETURN_HEADER_UPDATED} from '../../../../redux/action'
import {
    Grid,
    Title,
    Table,
    Text,
    Group,
    TextInput,
    Alert, List
} from '@mantine/core'
import { Search, InfoCircle } from 'tabler-icons-react';
import ProcessingOrderLine from './ProcessingOrderLine'

const ReturnProcessing = () => {
    const dispatch = useDispatch()
    const order = useSelector(state => state.app.order)
    const customer = useSelector((state) => {
        const customerIndex = _.findIndex(state.app.customers, cus => {
            return cus.id === order.customer_id
        })
        if (customerIndex > -1) {
            return state.app.customers[customerIndex]
        }
        return false
    })
    const reasons = []
    if (customer) {
        customer.options.map(op => {
            if (op.option_type === 'Reason') {
                reasons.push({
                    value: op.option_code,
                    label: op.option_value
                })
            }
        })
    }
    const [search, setSearch] = useState("")
    const filteredLines = _.filter(order.order_lines, l => {
        if (l.product) {
            return l.product.sku.includes(search) || l.product.upc.includes(search) || l.product.name_1.includes(search)
        } else {
            return true
        }
    })
    const returnBeingProcessed = useSelector(state => state.app.returnBeingProcessed)
    return (
        <Grid grow={true}>
            {order.order_status === 'Return Created' && order.returns.length > 0 ? <Grid.Col span={12}>
                <Alert icon={<InfoCircle size={16} />} title="Saved Return(s)" color="teal">
                    This order has the below return(s) recorded.
                    <List>
                        {order.returns.map(r => {
                            return <List.Item key={r.id}>
                                {r.id}
                            </List.Item>
                        })}
                    </List>
                </Alert>
            </Grid.Col> : null}
            <Grid.Col span={4}>
                <Title order={5} mb='lg'>Order Information</Title>
                <Table fontSize='sm'>
                    <tbody>
                    <tr>
                        <td>ID</td>
                        <td>{order.id}</td>
                    </tr>
                    <tr>
                        <td>Delivery #</td>
                        <td>{order.delivery_number}</td>
                    </tr>
                    <tr>
                        <td>Order #</td>
                        <td>{order.order_number}</td>
                    </tr>
                    <tr>
                        <td>Web Order #</td>
                        <td>{order.web_order_number}</td>
                    </tr>
                    <tr>
                        <td>Buyer Ref #</td>
                        <td>{order.buyer_reference}</td>
                    </tr>
                    <tr>
                        <td>Type | Source</td>
                        <td>{order.order_type} | {order.source}</td>
                    </tr>
                    </tbody>
                </Table>
            </Grid.Col>
            <Grid.Col span={4}>
                <Title order={5} mb='lg'>Customer Information</Title>
                {order.delivery_name_1 ? <Text size='sm'>{order.delivery_name_1}</Text> : null}
                {order.delivery_name_2 ? <Text size='sm'>{order.delivery_name_2}</Text> : null}
                {order.delivery_address_1 ? <Text size='sm'>{order.delivery_address_1}</Text> : null}
                {order.delivery_address_2 ? <Text size='sm'>{order.delivery_address_2}</Text> : null}
                {order.delivery_address_3 ? <Text size='sm'>{order.delivery_address_3}</Text> : null}
                <Group>
                    {order.delivery_city ? <Text size='sm'>{order.delivery_city}</Text> : null}
                    {order.delivery_state ? <Text size='sm'>{order.delivery_state}</Text> : null}
                    {order.delivery_zipcode ? <Text size='sm'>{order.delivery_zipcode}</Text> : null}
                    {order.delivery_country ? <Text size='sm'>{order.delivery_country}</Text> : null}
                </Group>
                {order.delivery_contact ? <Text size='sm'>{order.delivery_contact}</Text> : null}
                {order.delivery_phone ? <Text size='sm'>{order.delivery_phone}</Text> : null}
                {order.delivery_email ? <Text size='sm'>{order.delivery_email}</Text> : null}
            </Grid.Col>
            <Grid.Col span={4}>
                <Title order={5} mb='lg'>Return Option</Title>
                {/*{return_id: "__xxx", field: "reference_1", value: "something"}*/}
                <TextInput
                    label="Reference 1"
                    size='xs'
                    value={returnBeingProcessed.reference_1 ? returnBeingProcessed.reference_1 : ""}
                    onChange={(e) => {
                        dispatch({
                            type: CURRENT_RETURN_HEADER_UPDATED,
                            payload: {
                                field: 'reference_1',
                                value: e.currentTarget.value
                            }
                        })
                    }}
                />
                <TextInput
                    mt="md"
                    label="Reference 2"
                    size='xs'
                    value={returnBeingProcessed.reference_2 ? returnBeingProcessed.reference_2 : ""}
                    onChange={(e) => {
                        dispatch({
                            type: CURRENT_RETURN_HEADER_UPDATED,
                            payload: {
                                field: 'reference_2',
                                value: e.currentTarget.value
                            }
                        })
                    }}
                />
                <TextInput
                    mt="md"
                    label="Receiving Date"
                    type='date'
                    size='xs'
                    value={returnBeingProcessed.return_date ? returnBeingProcessed.return_date : ""}
                    onChange={(e) => {
                        dispatch({
                            type: CURRENT_RETURN_HEADER_UPDATED,
                            payload: {
                                field: 'return_date',
                                value: e.target.value
                            }
                        })
                    }}
                />


            </Grid.Col>
            <Grid.Col span={12}>
                <TextInput type='search'
                           value={search}
                           onChange={(e) => setSearch(e.currentTarget.value)}
                           icon={<Search size={14} />}/>
            </Grid.Col>
            <Grid.Col span={12}>
                {filteredLines.map(l => {
                    return <ProcessingOrderLine
                        line={l}
                        key={l.id}
                        cdn1={customer.cdn_url_1}
                        cdn2={customer.cdn_url_2}
                        customerReasons={reasons}
                        customerId={order.customer_id}
                        ledgers={order.ledgers}/>
                })}
            </Grid.Col>
        </Grid>
    )
}

ReturnProcessing.propTypes = {

}

export default ReturnProcessing