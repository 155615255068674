import {
    applyMiddleware, legacy_createStore as createStore
} from 'redux'
import thunk from 'redux-thunk'
import {
    composeWithDevTools
} from 'redux-devtools-extension'
import root from './stores/root'

let store

if (process.env.NODE_ENV === 'development') {
    store = createStore(root, composeWithDevTools(applyMiddleware(thunk)))
} else {
    store = createStore(root, applyMiddleware(thunk))
}

export default store