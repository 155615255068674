import React, {useState} from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import {
    PasswordInput, Box, Group, Button
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import api from '../../api'
import FormErrorMessages from '../utilities/FormErrorMessages'

const ResetPasswordFormSchema = Yup.object().shape({
    new_pass: Yup.string().required("New password is required"),
    new_pass_confirmed: Yup.string().oneOf([Yup.ref('new_pass')], 'Passwords must match')
})

const ResetPasswordForm = ({username, token}) => {
    const form = useForm({
        initialValues: {
            new_pass: '',
            new_pass_confirmed: ''
        },
        schema: yupResolver(ResetPasswordFormSchema)
    })

    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState([])
    return (
        <Box mx="auto">
            {formError.length > 0 ? <FormErrorMessages errors={formError}/> : null}
            <form onSubmit={form.onSubmit(async (values) => {
                setLoading(true)
                const {code, message} = await api.auth.submitReset(username, token, values.new_pass)
                if (code === 200) {
                    setFormError(["Your password is now updated"])
                    form.setValues({new_pass: "", new_pass_confirmed: ""})
                    setLoading(false)
                } else {
                    setFormError(message)
                    setLoading(false)
                }
            })}>
                <PasswordInput
                    label="Password"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('new_pass')}
                />
                <PasswordInput
                    label="Confirm Password"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('new_pass_confirmed')}
                />
                <Group position="left" mt="md">
                    <Button type="submit" color='sgl' loading={loading}>Reset</Button>
                </Group>
            </form>
        </Box>
    )
}

ResetPasswordForm.propTypes = {
    username: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired
}

export default ResetPasswordForm