import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {Alert, Box, Button, Table, Title} from '@mantine/core'
import _ from 'lodash'
import ConnectionForm from '../../../forms/ConnectionForm'


const ConnectionTable = ({customerId}) => {
    const customer = useSelector((state) => {
        const customerIndex = _.findIndex(state.app.customers, cus => {
            return cus.id === customerId
        })
        if (customerIndex > -1) {
            return state.app.customers[customerIndex]
        } else {
            return false
        }
    })

    const [connectionForm, setConnectionForm] = useState(null)

    return (
        customer ? <Box mx={20}>
            <Title order={3}>Options ({customer.company})</Title>
            <Alert title="Connection" color="teal">
                Each customer should have two connections. One inbound connection to Biztalk or FGL backend to retrieve orders.
                You should also create an outbound connection to Biztalk so that the system knows to send return XML files.
            </Alert>
            <Table captionSide="bottom" mt={20}>
                <caption>
                    <Button color='sgl' variant='subtle' onClick={() => {
                        setConnectionForm({
                            id: '_new',
                            customer_id: customer.id,
                            connection_type: 'Biztalk Inbound Order',
                            biztalk_schema: 'Default',
                            biztalk_sender: '',
                            biztalk_receiver: '',
                            endpoint: '',
                            username: '',
                            secure_short_token: '',
                            secure_long_token: '',
                            has_long_token: false,
                            has_short_token: false,
                            connection_meta: {}
                        })
                    }}>New Connection</Button>
                </caption>
                <thead>
                <tr>
                    <th>
                        Type
                    </th>
                    <th>
                        Schema
                    </th>
                    <th>
                        Biztalk Sender
                    </th>
                    <th>
                        Biztalk Receiver
                    </th>
                    <th>
                        FGL Endpoint
                    </th>
                    <th>
                        Username
                    </th>
                    <th>
                        Action
                    </th>
                </tr>
                </thead>
                <tbody>
                {customer.connections.map(op => {
                    return <tr key={op.id}>
                        <td>{op.connection_type}</td>
                        <td>{op.biztalk_schema}</td>
                        <td>{op.biztalk_sender}</td>
                        <td>{op.biztalk_receiver}</td>
                        <td>{op.endpoint}</td>
                        <td>{op.username}</td>
                        <td>
                            <Button color='sgl' variant='subtle' onClick={() => {
                                setConnectionForm({
                                    id: op.id,
                                    customer_id: op.id,
                                    connection_type: op.connection_type,
                                    biztalk_schema: op.biztalk_schema,
                                    biztalk_sender: op.biztalk_sender,
                                    biztalk_receiver: op.biztalk_receiver,
                                    endpoint: op.endpoint,
                                    username: op.username,
                                    secure_short_token: op.secure_short_token,
                                    secure_long_token: op.secure_long_token,
                                    connection_meta: op.connection_meta,
                                    has_long_token: op.has_long_token,
                                    has_short_token: op.has_short_token
                                })
                            }}>Edit</Button>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>

            {connectionForm ? <ConnectionForm customer={customer} onCloseForm={() => {
                setConnectionForm(null)
            }} initialValues={connectionForm} /> : null}

        </Box> : <Alert title='No customer found' mx={10}>
            Please refresh your page and try again.
        </Alert>
    )
}

ConnectionTable.propTypes = {
    customerId: PropTypes.number.isRequired
}

export default ConnectionTable