const sys = {
    token: {
        saveToken: (token, expire) => {
            localStorage.setItem("@sgl_return_token", token)
            localStorage.setItem("@sgl_return_expire", expire)
        },
        getToken: () => {
            return {
                token: localStorage.getItem("@sgl_return_token"),
                expire: localStorage.getItem("@sgl_return_expire")
            }
        },
        clearToken: () => {
            localStorage.removeItem("@sgl_return_token")
            localStorage.removeItem("@sgl_return_expire")
        }
    }
}

export default sys