import React, {useState} from 'react'
import * as Yup from 'yup'
import {
    TextInput, Box, Group, Button
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import FormErrorMessages from '../utilities/FormErrorMessages'
import api from '../../api'

const ForgetPasswordFormSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email().required("Email is not valid")
})

const ForgetPasswordForm = () => {
    const form = useForm({
        initialValues: {
            username: '',
            email: ''
        },
        schema: yupResolver(ForgetPasswordFormSchema)
    })

    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState([])
    return (
        <Box mx="auto">
            {formError.length > 0 ? <FormErrorMessages errors={formError}/> : null}
            <form onSubmit={form.onSubmit(async (values) => {
                setLoading(true)
                const {code, message} = await api.auth.forgetPassword(values)
                if (code === 200) {
                    setFormError(["Please check your email to reset password."])
                    form.setValues({username: "", email: ""})
                } else {
                    setFormError(message)
                }
                setLoading(false)
            })}>
                <TextInput
                    label="Username"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('username')}
                />
                <TextInput
                    label="Email"
                    placeholder=""
                    mt="sm"
                    {...form.getInputProps('email')}
                />
                <Group position="left" mt="md">
                    <Button type="submit" color='sgl' loading={loading}>Reset</Button>
                </Group>
            </form>
        </Box>
    )
}

export default ForgetPasswordForm