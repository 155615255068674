import React, {useState} from 'react'
import {PasswordInput, Box, Group, Button, Alert} from '@mantine/core'
import * as Yup from 'yup'
import { useForm, yupResolver } from '@mantine/form';
import api from '../../api'

const PasswordForm = () => {
    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState({code: null, message: ""})
    const CustomerOptionSchema = Yup.object().shape({
        old_password: Yup.string().required("Old password is required"),
        password: Yup.string().required("New password is required"),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'New passwords must match')
    })

    const form = useForm({
        initialValues: {
            old_password: "",
            password: "",
            password_confirmation: ""
        },
        schema: yupResolver(CustomerOptionSchema)
    })
    return <Box mt={10}>
        <form onSubmit={form.onSubmit(async (values) => {
            setLoading(true)
            const result = await api.util.updatePassword(values)
            console.log(result)
            if (result.code === 200) {
                form.reset()
            }
            setMsg(result)
            setLoading(false)
        })}>
            {msg.code && msg.message && <Alert title={msg.code === 200 ? "Password Updated" : "Error"} color={msg.code === 200 ? "green" : "red"}>
                {msg.code === 200 ? "Your password has been updated successfully" : msg.message}
            </Alert>}
            <PasswordInput required label="Current Password" {...form.getInputProps('old_password')} />
            <PasswordInput required label="New Password" {...form.getInputProps('password')} mt='md'/>
            <PasswordInput required label="Confirm New Password" {...form.getInputProps('password_confirmation')} mt='md'/>
            <Group position="left" mt="md">
                <Button type="submit" color='sgl' loading={loading}>Save</Button>
            </Group>
        </form>
    </Box>
}

export default PasswordForm