import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import api from '../../../api'
import {Drawer, Container} from '@mantine/core'
import {SET_CUSTOMERS_LIST} from '../../../redux/action'
import CustomerTable from './partials/CustomerTable'
import CustomerForm from '../../forms/CustomerForm'
import CustomerOptions from './partials/CustomerOptions'
import ConnectionTable from './partials/ConnectionTable'

const CustomerSetting = () => {
    const dispatch = useDispatch()
    const [showFormType, setShowFormType] = useState(null)
    const [customerId, setCustomerId] = useState(0)
    const [customerFormInitValue, setCustomerFormInitValue] = useState(null)
    const authChecking = useSelector((state) => state.app.authChecking)
    useEffect(() => {
        const storeLoadCustomers = async () => {
            const {code, message} = await api.admin.getCustomers()
            if (code === 200) {
                dispatch({type: SET_CUSTOMERS_LIST, payload: message})
            } else {
                dispatch({type: SET_CUSTOMERS_LIST, payload: []})
            }
        }
        if (!authChecking) {
            storeLoadCustomers()
        }
    }, [authChecking, dispatch])
    return (
        <Container size='xl'>
            <CustomerTable onEditCustomerClick={(clickable_type, value) => {
                if (value) {
                    if (clickable_type === 'edit') {
                        setShowFormType('edit')
                        setCustomerFormInitValue(null)
                        setCustomerFormInitValue({
                            id: value.id,
                            company: value.company,
                            can_blind: value.can_blind,
                            customer_status: value.customer_status,
                            order_source: value.order_source,
                            cdn_url_1: value.cdn_url_1,
                            cdn_url_2: value.cdn_url_2
                        })
                    }
                    if (clickable_type === 'option') {
                        setShowFormType('option')
                        setCustomerId(value.id)
                    }
                    if (clickable_type === 'connection') {
                        setShowFormType('connection')
                        setCustomerId(value.id)
                    }
                } else {
                    setShowFormType('edit')
                    setCustomerFormInitValue(null)
                    setCustomerFormInitValue({
                        id: '_new',
                        company: "",
                        can_blind: true,
                        customer_status: "Active",
                        order_source: "Biztalk",
                        cdn_url_1: "",
                        cdn_url_2: ""
                    })
                }
            }} />
            <Drawer
                opened={['edit', 'option', 'connection'].includes(showFormType)}
                size={showFormType === 'connection' ? 800 : 'lg'}
                position='right'
                closeOnClickOutside={false}
                onClose={() => {
                    if (showFormType === 'edit') {
                        setCustomerFormInitValue(null)
                    }
                    if (showFormType === 'option') {
                        setCustomerId(0)
                    }
                    if (showFormType === 'connection') {
                        setCustomerId(0)
                    }
                    setShowFormType(null)
                }}>
                {!!customerFormInitValue && showFormType === 'edit' ? <CustomerForm onCloseDraw={() => {
                    setCustomerFormInitValue(null)
                    setShowFormType(null)
                }} initialValues={customerFormInitValue} /> : null}
                {customerId > 0 && showFormType === 'option' ? <CustomerOptions customerId={customerId} /> : null}
                {customerId > 0 && showFormType === 'connection' ? <ConnectionTable customerId={customerId} /> : null}
            </Drawer>
        </Container>
    )
}

export default CustomerSetting