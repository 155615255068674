import React from "react";
import PropTypes from "prop-types";
import {
    Grid,
    Table
} from '@mantine/core'

const ReturnViewTable = ({model}) => {
    return <Grid grow mt='lg'>
        <Grid.Col span={12}>
            <Table>
                <thead>
                <tr>
                    <th>Product</th>
                    <th>Returned</th>
                    <th>Condition</th>
                    <th>Reason</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                {model.lines.map(l => {
                    return <tr key={l.id}>
                        <td>
                            {l.product.sku} <br />
                            {l.product.name_1}
                        </td>
                        <td>
                            {l.quantity}
                        </td>
                        <td>{l.condition}</td>
                        <td>{l.reason}</td>
                        <td>{l.comment}</td>
                    </tr>
                })}
                </tbody>
            </Table>
        </Grid.Col>
    </Grid>
}

ReturnViewTable.propTypes = {
    model: PropTypes.shape({
        lines: PropTypes.array.isRequired
    }).isRequired
}

export default ReturnViewTable