import React from 'react'
import axios from 'axios'
import sys from './sys'
import _ from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { showNotification } from '@mantine/notifications';
import ApiLoadingError from './components/utilities/ApiLoadingError'
import {X} from 'tabler-icons-react'
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
function setHttpHeader() {
    const {
        token
    } = sys.token.getToken()

    axios.defaults.headers.common["Authorization"] = token;
}

const api = {
    helpers: {
        toLocalDate: (dbDate) => {
            dayjs.extend(utc)
            if (!dbDate) {
                return ";"
            }
            return dayjs.utc(dbDate).local().format('MMM DD, YYYY');
        },
        toLocalDateTime: (dbDate) => {
            dayjs.extend(utc)
            if (!dbDate) {
                return ";"
            }
            return dayjs.utc(dbDate).local().format('MMM DD HH:mm');
        }
    },
    handleError: (e) => {
        if (_.isObject(e) && e.name === 'AxiosError') {
            if (e.response.status === 401) {
                return {
                    code: 401,
                    message: ["Unauthorized."]
                }
            }
            if (e.response.status === 400) {
                return {
                    code: 400,
                    message: _.isObject(e.response.data) && e.response.data.message ? [e.response.data.message] : ["Client error, refresh your page or contact support for help."]
                }
            }
            if (e.response.status === 422) {
                return {
                    code: 422,
                    message: _.isObject(e.response.data) && e.response.data.message
                }
            }
        }
        return {
            code: -1,
            message: ["Client error, refresh your page or contact support for help."]
        }
    },

    notify: (message = [], code = 400) => {
        showNotification({
            title: code === 422 ? "Data Validation Error" : "API Server Error",
            message: <ApiLoadingError errors={message} />,
            color: 'red',
            icon: <X size={16} />
        })
    },

    report: async (values) => {
        try {
            setHttpHeader()
            const {data} = await axios.post(`/api/reports`, values)
            return {code: 200, message: data}
        } catch (e) {
            let errors = api.handleError(e)
            api.notify(errors.message, errors.code)
            return errors
        }
    },

    returns: {
        productSearch: async (customer_id, search) => {
            try {
                setHttpHeader()
                const {data} = await axios.put(`/api/returns/products/${customer_id}`, {search})
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        returnList: async (paramString) => {
            try {
                setHttpHeader()
                const {data} = await axios.get(`/api/returns?${paramString}`)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        ///api/search/:customer_id
        orderSearch: async (customerId, search) => {
            try {
                setHttpHeader()
                const {data} = await axios.put(`/api/returns/search/${customerId}`, {search})
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        getOrderById: async (source, orderId, apiData) => {
            //returns/order/:source/:order_id
            try {
                setHttpHeader()
                const {data} = await axios.put(`/api/returns/order/${source}/${orderId}`, apiData)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        readReturn: async (order_id, return_id) => {
            try {
                setHttpHeader()
                const {data} = await axios.get(`/api/returns/${return_id}/order/${order_id}`)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        submitReturn: async (form) => {
            ///api/returns
            try {
                setHttpHeader()
                const {data} = await axios.post('/api/returns', form)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        submitBlind: async (customerId, form) => {
            ///api/returns/blind/${customerId}
            try {
                setHttpHeader()
                const {data} = await axios.put(`/api/returns/blind/${customerId}`, form)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        }
    },

    auth: {
        login: async (form) => {
            try {
                const {data} = await axios.post("/api/auth/login", form)
                const {token, expire} = data
                sys.token.saveToken(token, expire)
                return {
                    code: 200,
                    message: null
                }
            } catch (e) {
                return {
                    code: 401,
                    message: ["Login failed"]
                }
            }
        },
        forgetPassword: async (form) => {
            try {
                await axios.post("/api/auth/forget-password", form)
                return {
                    code: 200,
                    message: null
                }
            } catch (e) {
                return api.handleError(e)
            }
        },
        verifyToken: async (username, token) => {
            try {
                await axios.post("/api/auth/validate-reset-token", {username, token})
                return {code: 200, message: null}
            } catch (e) {
                return api.handleError(e)
            }
        },
        submitReset: async (username, token, new_pass) => {
            try {
                await axios.post("/api/auth/confirm-password", {username, token, new_pass})
                return {code: 200, message: null}
            } catch (e) {
                return api.handleError(e)
            }
        }
    },

    util: {
        refreshToken: async () => {
            try {
                setHttpHeader()
                const {data} = await axios.post("/api/account/token", {})
                const {token, expire, user, customers} = data
                sys.token.saveToken(token, expire)
                return {
                    code: 200,
                    message: {account: user, customers: customers}
                }
            } catch (e) {
                return {
                    code: 401,
                    message: null
                }
            }
        },
        updatePassword: async (values) => {
            try {
                setHttpHeader()
                await axios.post("/api/account/password", values)
                return {
                    code: 200,
                    message: "Success"
                }
            } catch (e) {
                return {
                    code: -1,
                    message: e.response.data.errors
                }
            }
        }
    },

    admin: {
        getUsers: async () => {
            try {
                setHttpHeader()
                const {data} = await axios.get("/api/su/users")
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        createUser: async (values) => {
            try {
                setHttpHeader()
                const {data} = await axios.post("/api/su/users", values)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        updateUser: async (id, values) => {
            try {
                setHttpHeader()
                const {data} = await axios.put(`/api/su/users/${id}`, values)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        getCustomers: async () => {
            try {
                setHttpHeader()
                const {data} = await axios.get(`/api/su/customers`)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        createCustomer: async (values) => {
            try {
                setHttpHeader()
                const {data} = await axios.post(`/api/su/customers`, values)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        updateCustomer: async (id, values) => {
            try {
                setHttpHeader()
                const {data} = await axios.put(`/api/su/customers/${id}`, values)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        createConnection: async (customerId, values) => {
            try {
                setHttpHeader()
                const {data} = await axios.post(`/api/su/customers/${customerId}/connections`, values)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        updateConnection: async (customerId, connectionId, values) => {
            try {
                setHttpHeader()
                const {data} = await axios.put(`/api/su/customers/${customerId}/connections/${connectionId}`, values)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        deleteConnection: async (customerId, connectionId) => {
            try {
                setHttpHeader()
                await axios.delete(`/api/su/customers/${customerId}/connections/${connectionId}`)
                return {code: 200, message: null}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        createOption: async (customerId, values) => {
            try {
                setHttpHeader()
                const {data} = await axios.post(`/api/su/customers/${customerId}/options`, values)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        updateOption: async (customerId, optionId, values) => {
            try {
                setHttpHeader()
                const {data} = await axios.put(`/api/su/customers/${customerId}/options/${optionId}`, values)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        deleteOption: async (customerId, optionId) => {
            try {
                setHttpHeader()
                await axios.delete(`/api/su/customers/${customerId}/options/${optionId}`)
                return {code: 200, message: null}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        loadSystemSettings: async () => {
            try {
                setHttpHeader()
                const {data} = await axios.get('/api/su/settings')
                return {code: 200, message: data.setting}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        updateOneSystemSettings: async (key, value) => {
            try {
                setHttpHeader()
                await axios.post('/api/su/settings', {setting_key: key, setting_value: value})
                return {code: 200, message: null}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        getSystemTasks: async (page, search) => {
            try {
                let url = `/api/su/tasks?page=${page}`
                if (search) {
                    url = `${url}&${search}`
                }
                setHttpHeader()
                const {data} = await axios.get(url)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        updateSystemTask: async (taskId, values) => {
            try {
                setHttpHeader()
                const {data} = await axios.put(`/api/su/tasks/${taskId}`, values)
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
        getTaskFileUrl: async (taskId) => {
            //su/tasks/:id/download
            try {
                setHttpHeader()
                const {data} = await axios.put(`/api/su/tasks/${taskId}/download`, {})
                return {code: 200, message: data}
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },

        ///api/search/:customer_id
        doNotUse: async (data) => {
            try {
                setHttpHeader()
            } catch (e) {
                let errors = api.handleError(e)
                api.notify(errors.message, errors.code)
                return errors
            }
        },
    }
}

export default api