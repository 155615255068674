import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import api from '../../../api'
import {Drawer, Container} from '@mantine/core'
import {SET_USERS_LIST} from '../../../redux/action'
import UserTable from './partials/UserTable'
import UserForm from '../../forms/UserForm'

const UserSetting = () => {
    const dispatch = useDispatch()
    const [userFormInitialValue, setUserFormInitialValue] = useState(null)
    const authChecking = useSelector((state) => state.app.authChecking)
    useEffect(() => {
        const storeLoadUsers = async () => {
            const {code, message} = await api.admin.getUsers()
            if (code === 200) {
                dispatch({type: SET_USERS_LIST, payload: message})
            } else {
                dispatch({type: SET_USERS_LIST, payload: []})
            }
        }
        if (!authChecking) {
            storeLoadUsers()
        }
    }, [authChecking, dispatch])
    return (
        <Container size='xl'>
            <UserTable onEditUserClick={(value) => {
                if (value) {
                    setUserFormInitialValue(null)
                    setUserFormInitialValue({
                        id: value.id,
                        name: value.name,
                        email: value.email,
                        is_su: value.is_su,
                        user_status: value.user_status
                    })
                } else {
                    setUserFormInitialValue(null)
                    setUserFormInitialValue({
                        id: '_new',
                        name: "",
                        username: "",
                        password: "",
                        pass_confirmed: "",
                        email: "",
                        user_status: "Active",
                        is_su: false
                    })
                }
            }} />
            <Drawer
                opened={!!userFormInitialValue}
                position='right'
                closeOnClickOutside={false}
                onClose={() => {setUserFormInitialValue(null)}}>
                {!!userFormInitialValue ? <UserForm onCloseDraw={() => {
                    setUserFormInitialValue(null)
                }} initialValues={userFormInitialValue} /> : null}
            </Drawer>
        </Container>
    )
}

export default UserSetting