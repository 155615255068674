export const TOGGLE_AUTH_CHECKING = "TOGGLE_AUTH_CHECKING"
export const USER_LOGGED_IN = "USER_LOGGED_IN"
export const USER_LOGGED_OUT = "USER_LOGGED_OUT"
export const SET_USERS_LIST = "SET_USERS_LIST"
export const NEW_USER_CREATED = "NEW_USER_CREATED"
export const USER_UPDATED = "USER_UPDATED"
export const SET_CUSTOMERS_LIST = "SET_CUSTOMERS_LIST"
export const NEW_CUSTOMER_CREATED = "NEW_CUSTOMER_CREATED"
export const CUSTOMER_UPDATED = "CUSTOMER_UPDATED"
export const NEW_CUSTOMER_OPTION_CREATED = "NEW_CUSTOMER_OPTION_CREATED"
export const CUSTOMER_OPTION_UPDATED = "CUSTOMER_OPTION_UPDATED"
export const CUSTOMER_OPTION_DELETED = "CUSTOMER_OPTION_DELETED"
export const NEW_CUSTOMER_CONNECTION_CREATED = "NEW_CUSTOMER_CONNECTION_CREATED"
export const CUSTOMER_CONNECTION_UPDATED = "CUSTOMER_CONNECTION_UPDATED"
export const CUSTOMER_CONNECTION_DELETED = "CUSTOMER_CONNECTION_DELETED"
export const SET_ORDER_IN_STORE_FOR_PROCESSING = "SET_ORDER_IN_STORE_FOR_PROCESSING"
export const CLEAR_ORDER_IN_STORE_FOR_PROCESSING = "CLEAR_ORDER_IN_STORE_FOR_PROCESSING"
export const CURRENT_RETURN_HEADER_UPDATED = "CURRENT_RETURN_HEADER_UPDATED"
export const ADD_NEW_LEDGER_TO_RETURN = "ADD_NEW_LEDGER_TO_RETURN"
export const DELETE_RETURN_LEDGER = "DELETE_RETURN_LEDGER"
export const NEW_RETURN_CREATED_VIA_API = "NEW_RETURN_CREATED_VIA_API"
export const SET_HISTORY_PAGINATED_RESULT = "SET_HISTORY_PAGINATED_RESULT"

export const setUserInStore = ({account, customers}) => (dispatch) => {
    //do something - api
    //then
    dispatch({
        type: USER_LOGGED_IN,
        payload: {
            account, customers
        }
    })
}