import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {Alert, Box, Button, Table, Title} from '@mantine/core'
import _ from 'lodash'
import OptionForm from '../../../forms/OptionForm'

const CustomerOptions = ({customerId}) => {
    const customer = useSelector((state) => {
        const customerIndex = _.findIndex(state.app.customers, cus => {
            return cus.id === customerId
        })
        if (customerIndex > -1) {
            return state.app.customers[customerIndex]
        } else {
            return false
        }
    })

    const [optionForm, setOptionForm] = useState(null)

    return (
        customer ? <Box mx={10}>
            <Title order={3}>Options ({customer.company})</Title>
            <Table captionSide="bottom" mt={20}>
                <caption>
                    <Button color='sgl' variant='subtle' onClick={() => {
                        setOptionForm({
                            id: '_new',
                            customer_id: customer.id,
                            option_type: 'Reason',
                            option_code: '',
                            option_value: ''
                        })
                    }}>Add New Option</Button>
                </caption>
                <thead>
                    <tr>
                        <th>
                            Type
                        </th>
                        <th>
                            Code
                        </th>
                        <th>
                            Value
                        </th>
                        <th>
                            Edit
                        </th>
                    </tr>
                </thead>
                <tbody>
                {customer.options.map(op => {
                    return <tr key={op.id}>
                        <td>{op.option_type}</td>
                        <td>{op.option_code}</td>
                        <td>{op.option_value}</td>
                        <td>
                            <Button color='sgl' variant='subtle' onClick={() => {
                                setOptionForm({
                                    id: op.id,
                                    customer_id: customer.id,
                                    option_type: op.option_type,
                                    option_code: op.option_code,
                                    option_value: op.option_value
                                })
                            }}>Edit</Button>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>

            {optionForm ? <OptionForm customer={customer} onCloseForm={() => {
                setOptionForm(null)
            }} initialValues={optionForm} /> : null}
        </Box> : <Alert title='No customer found' mx={10}>
            Please refresh your page and try again.
        </Alert>
    )
}

CustomerOptions.propTypes = {
    customerId: PropTypes.number.isRequired
}

export default CustomerOptions