import React from 'react'
import {
    Routes, Route
} from 'react-router-dom'

import Guest from './Guest'
import Login from '../components/pages/auth/Login'
import ForgetPassword from '../components/pages/auth/ForgetPassword'
import ResetPassword from '../components/pages/auth/ResetPassword'


import Protected from './Protected'
import Receiving from '../components/pages/return/Receiving'
import BlindReceiving from '../components/pages/return/BlindReceiving'
import ReturnHistory from '../components/pages/return/ReturnHistory'
import ReturnOrderView from "../components/pages/return/ReturnOrderView";
import User from '../components/pages/settings/User'
import Customer from '../components/pages/settings/Customer'
import System from '../components/pages/settings/System'
import Task from '../components/pages/settings/Task'


const AppRoutes = (props) => {
    return (
        <Routes>
            <Route path="/" element={<Protected />}>
                <Route path="/history" exact {...props} element={<ReturnHistory />} />
                <Route path="/" exact {...props} element={<Receiving />} />
                <Route path="/orders/:order_id/returns/:return_id" exact {...props} element={<ReturnOrderView />} />
                <Route path="/blind/:customer_id" exact {...props} element={<BlindReceiving />} />
                <Route path="/admin/customers" exact {...props} element={<Customer />} />
                <Route path="/admin/systems" exact {...props} element={<System />} />
                <Route path="/admin/users" exact {...props} element={<User />} />
                <Route path="/admin/tasks" exact {...props} element={<Task />} />
            </Route>
            <Route path="/auth" element={<Guest />}>
                <Route path="login" exact {...props} element={<Login />} />
                <Route path="forget-password" exact {...props} element={<ForgetPassword />} />
                <Route path="password/:username/:token" exact {...props} element={<ResetPassword />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes