import update from 'immutability-helper';
import _ from 'lodash';
import dayjs from 'dayjs'
import {
    USER_LOGGED_IN,
    USER_LOGGED_OUT,
    SET_USERS_LIST,
    TOGGLE_AUTH_CHECKING,
    USER_UPDATED,
    NEW_USER_CREATED,
    SET_CUSTOMERS_LIST,
    NEW_CUSTOMER_CREATED,
    CUSTOMER_UPDATED,
    NEW_CUSTOMER_OPTION_CREATED,
    CUSTOMER_OPTION_UPDATED,
    CUSTOMER_OPTION_DELETED,
    NEW_CUSTOMER_CONNECTION_CREATED,
    CUSTOMER_CONNECTION_UPDATED,
    CUSTOMER_CONNECTION_DELETED,
    SET_ORDER_IN_STORE_FOR_PROCESSING,
    CLEAR_ORDER_IN_STORE_FOR_PROCESSING,
    CURRENT_RETURN_HEADER_UPDATED,
    ADD_NEW_LEDGER_TO_RETURN,
    DELETE_RETURN_LEDGER,
    NEW_RETURN_CREATED_VIA_API,
    SET_HISTORY_PAGINATED_RESULT
} from '../action'

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export default function app(state = {
    authChecking: true,
    sidebarOpen: true,
    authenticated: false,
    account: {
        id: null
    },
    customers: [],
    users: [],
    system: {

    },
    order: {
        id: null,
        order_lines: [],
        returns: [],
        ledgers: []
    },
    isProcessingOrder: false,
    returnBeingProcessed: {id: null, return_ledgers: []},
    history: {
        data: [],
        on_page: 1,
        per_page: 30,
        total_records: 0,
        total_pages: 0
    }
}, action = {}) {
    switch(action.type) {
        case SET_HISTORY_PAGINATED_RESULT:
            return update(state, {
                history: {$set: action.payload}
            })

        case NEW_RETURN_CREATED_VIA_API:
            /*
            {return: {xxx}, ledgers: [xxx]}
             */
            return update(state, {
                order: {
                    order_status: {$set: 'Return Created'},
                    returns: {$push: [action.payload.return]},
                    ledgers: {$push: action.payload.ledgers}
                },
                returnBeingProcessed: {$set: {
                        id: `__${makeid(6)}`,
                        customer_id: action.payload.customer_id,
                        order_id: action.payload.id,
                        user_id: state.account.id,
                        username: state.account.username,
                        reference_1: "",
                        reference_2: "",
                        return_date: dayjs().format("YYYY-MM-DD"),
                        is_blind: false,
                        return_ledgers: []
                    }}
            })

        case ADD_NEW_LEDGER_TO_RETURN:
            return update(state, {
                returnBeingProcessed: {
                    return_ledgers: {$push: [action.payload]}
                }
            })

        case DELETE_RETURN_LEDGER:
            /*
            {ledger_id: 'xxx', return_id: 'xxx'}
             */
            if (action.payload.return_id) {
                const deleteExistingLedgerIndex = _.findIndex(state.order.ledgers, l => {
                    return l.id === action.payload.ledger_id
                })
                if (deleteExistingLedgerIndex > -1) {
                    return update(state, {
                        order: {
                            ledgers: {
                                $splice: [[deleteExistingLedgerIndex, 1]]
                            }
                        }
                    })
                }
                return state
            } else {
                const deleteNewLedgerIndex = _.findIndex(state.returnBeingProcessed.return_ledgers, l => {
                    return l.id === action.payload.ledger_id
                })
                if (deleteNewLedgerIndex > -1) {
                    return update(state, {
                        returnBeingProcessed: {
                            return_ledgers: {
                                $splice: [[deleteNewLedgerIndex, 1]]
                            }
                        }
                    })
                }
                return state
            }

        /*
         {field: "reference_1", value: "something"}
         */
        case CURRENT_RETURN_HEADER_UPDATED:
            return update(state, {
                returnBeingProcessed: {
                    [action.payload.field]: {$set: action.payload.value}
                }
            })

        case CLEAR_ORDER_IN_STORE_FOR_PROCESSING:
            return(update(state, {
                order: {$set: {
                        id: null,
                        order_lines: [],
                        returns: [],
                        ledgers: []
                    }},
                isProcessingOrder: {$set: false},
                returnBeingProcessed: {$set: {
                    id: null,
                    return_ledgers: []
                }}
            }))

        case SET_ORDER_IN_STORE_FOR_PROCESSING:
            const newId = `__${makeid(6)}`
            const newReturn = {
                id: newId,
                customer_id: action.payload.customer_id,
                order_id: action.payload.id,
                user_id: state.account.id,
                username: state.account.username,
                reference_1: "",
                reference_2: "",
                return_date: dayjs().format("YYYY-MM-DD"),
                is_blind: false,
                return_ledgers: []
            }
            return(update(state, {
                order: {$set: action.payload},
                isProcessingOrder: {$set: true},
                returnBeingProcessed: {$set: newReturn}
            }))

        case NEW_CUSTOMER_CONNECTION_CREATED:
            const connectionNewCustomerIndex = _.findIndex(state.customers, cus => {
                return cus.id === action.payload.customerId
            })
            if (connectionNewCustomerIndex > -1) {
                return update(state, {
                    customers: {
                        [connectionNewCustomerIndex]: {
                            connections: {$push: [action.payload.data]}
                        }
                    }
                })
            }
            return state

        case CUSTOMER_CONNECTION_UPDATED:
            const connectionUpdateCustomerIndex = _.findIndex(state.customers, cus => {
                return cus.id === action.payload.customerId
            })
            if (connectionUpdateCustomerIndex > -1) {
                const connectionUpdatedConnectionIndex = _.findIndex(state.customers[connectionUpdateCustomerIndex].connections, op => {
                    return op.id === action.payload.optionId
                })
                if (connectionUpdatedConnectionIndex > -1) {
                    return update(state, {
                        customers: {
                            [connectionUpdateCustomerIndex]: {
                                connections: {
                                    [connectionUpdatedConnectionIndex]: {$set: action.payload.data}
                                }
                            }
                        }
                    })
                }
            }
            return state

        case CUSTOMER_CONNECTION_DELETED:
            const connectionDeleteCustomerIndex = _.findIndex(state.customers, cus => {
                return cus.id === action.payload.customerId
            })
            if (connectionDeleteCustomerIndex > -1) {
                const connectionDeleteConnectionIndex = _.findIndex(state.customers[connectionDeleteCustomerIndex].connections, op => {
                    return op.id === action.payload.optionId
                })
                if (connectionDeleteConnectionIndex > -1) {
                    return update(state, {
                        customers: {
                            [connectionDeleteCustomerIndex]: {
                                connections: {$splice: [[connectionDeleteConnectionIndex, 1]]}
                            }
                        }
                    })
                }
            }
            return state

        case NEW_CUSTOMER_OPTION_CREATED:
            const optionNewCustomerIndex = _.findIndex(state.customers, cus => {
                return cus.id === action.payload.customerId
            })
            if (optionNewCustomerIndex > -1) {
                return update(state, {
                    customers: {
                        [optionNewCustomerIndex]: {
                            options: {$push: [action.payload.data]}
                        }
                    }
                })
            }
            return state

        case CUSTOMER_OPTION_UPDATED:
            const optionUpdateCustomerIndex = _.findIndex(state.customers, cus => {
                return cus.id === action.payload.customerId
            })
            if (optionUpdateCustomerIndex > -1) {
                const optionUpdatedOptionIndex = _.findIndex(state.customers[optionUpdateCustomerIndex].options, op => {
                    return op.id === action.payload.optionId
                })
                if (optionUpdatedOptionIndex > -1) {
                    return update(state, {
                        customers: {
                            [optionUpdateCustomerIndex]: {
                                options: {
                                    [optionUpdatedOptionIndex]: {$set: action.payload.data}
                                }
                            }
                        }
                    })
                }
            }
            return state

        case CUSTOMER_OPTION_DELETED:
            const optionDeleteCustomerIndex = _.findIndex(state.customers, cus => {
                return cus.id === action.payload.customerId
            })
            if (optionDeleteCustomerIndex > -1) {
                const optionDeleteOptionIndex = _.findIndex(state.customers[optionDeleteCustomerIndex].options, op => {
                    return op.id === action.payload.optionId
                })
                if (optionDeleteOptionIndex > -1) {
                    return update(state, {
                        customers: {
                            [optionDeleteCustomerIndex]: {
                                options: {$splice: [[optionDeleteOptionIndex, 1]]}
                            }
                        }
                    })
                }
            }
            return state

        case NEW_CUSTOMER_CREATED:
            return update(state, {
                customers: {$push: [action.payload]}
            })

        case CUSTOMER_UPDATED:
            const updateCustomerIndex = _.findIndex(state.customers, customer => {
                return customer.id === action.payload.id
            })
            if (updateCustomerIndex > -1) {
                return update(state, {
                    customers: {
                        [updateCustomerIndex]: {
                            company: {$set: action.payload.company},
                            can_blind: {$set: action.payload.can_blind},
                            customer_status: {$set: action.payload.customer_status},
                            order_source: {$set: action.payload.order_source},
                            updated_at: {$set: action.payload.updated_at},
                        }
                    }
                })
            }
            return state

        case SET_CUSTOMERS_LIST:
            return update(state, {
                customers: {$set: action.payload}
            })

        case NEW_USER_CREATED:
            return update(state, {
                users: {$push: [action.payload]}
            })

        case USER_UPDATED:
            const updateUserIndex = _.findIndex(state.users, user => {
                return user.id === action.payload.id
            })
            if (updateUserIndex > -1) {
                return update(state, {
                    users: {
                        [updateUserIndex]: {$set: action.payload}
                    }
                })
            }
            return state

        case SET_USERS_LIST:
            return update(state, {
                users: {$set: action.payload}
            })

        case USER_LOGGED_IN:
            return update(state, {
                authenticated: {$set: true},
                account: {$set: action.payload.account},
                customers: {$set: action.payload.customers},
            })

        case USER_LOGGED_OUT:
            return {
                authChecking: true,
                sidebarOpen: true,
                authenticated: false,
                account: {
                    id: null
                },
                customers: [],
                users: [],
                system: {

                },
                order: {
                    id: null,
                    order_lines: [],
                    returns: [],
                    ledgers: []
                },
                isProcessingOrder: false,
                returnBeingProcessed: {id: null, return_ledgers: []},
                history: {

                }
            }

        case TOGGLE_AUTH_CHECKING:
            return update(state, {
                authChecking: {$set: action.payload}
            })

        default:
            return state
    }
}