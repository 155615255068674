import React from "react";
import PropTypes from "prop-types";
import {Anchor, Grid, Modal, Title} from "@mantine/core";
import api from "../../../../api";
import ReactJson from "react-json-view";
import _ from "lodash";
import TaskForm from "../../../forms/TaskForm";

const TaskModal = ({task, setTask, taskUpdated}) => {
    return             <Modal
        opened={!!task.id}
        onClose={() => {setTask({id: null})}}
        title="Task Detail"
        size={800}
        closeOnClickOutside={false}
    >
        <Grid grow={true}>
            <Grid.Col span={6}>
                <Title order={6}>Task Logs</Title>
                <Anchor onClick={async () => {
                    const {code, message} = await api.admin.getTaskFileUrl(task.id)
                    if (code === 200) {
                        const {url} = message
                        window.open(url);
                    }
                }} mt='xl'>download file</Anchor>
                <ReactJson src={_.isObject(task.details) ? task.details : {}} />
            </Grid.Col>
            <Grid.Col span={6}>
                <Title order={6}>Task Action</Title>
                {task.id ? <TaskForm initialValues={task} taskUpdated={(t) => {
                    taskUpdated(t)
                }} /> : null}
            </Grid.Col>
        </Grid>
    </Modal>
}

TaskModal.propTypes = {
    task: PropTypes.object.isRequired,
    setTask: PropTypes.func.isRequired,
    taskUpdated: PropTypes.func.isRequired
}

export default TaskModal