import React, {useEffect, useState} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { AppShell } from '@mantine/core';
import TopNav from '../components/partials/TopNav'
import sys from '../sys'
import api from '../api'
import {
    setUserInStore, TOGGLE_AUTH_CHECKING
} from '../redux/action'

function Protected() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const authenticated = useSelector((state) => state.app.authenticated)
    const isCheckingToken = useSelector((state) => state.app.authChecking)
    const [sidebarOpen, setSidebarOpen] = useState(true)
    useEffect(() => {
        const verifyToken = async () => {
            dispatch({type: TOGGLE_AUTH_CHECKING, payload: true})
            //Handle authenticate or token refresh and etc...
            const {token, expire} = sys.token.getToken()
            if (!token || !expire) {
                navigate('/auth/login', {
                    replace: true
                })
            }
            if (token && !authenticated) {
                //We just reset the token...
                const {code, message} = await api.util.refreshToken()
                // console.log(code, message)
                if (code === 200) {
                    dispatch(setUserInStore(message))
                } else {
                    sys.token.clearToken()
                    navigate('/auth/login', {
                        replace: true
                    })
                }
            }
            dispatch({type: TOGGLE_AUTH_CHECKING, payload: false})
        }
        verifyToken()
    })

    if (isCheckingToken) {
        return <div>
            checking account status...
        </div>
    } else {
        return <AppShell
            padding="md"
            header={<TopNav opened={sidebarOpen} setOpened={setSidebarOpen}/>}
        >
            <Outlet />
        </AppShell>
    }
}

export default Protected