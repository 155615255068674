import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Card, Title, List, ThemeIcon, Tooltip, Anchor, Table} from '@mantine/core'
import {DatabaseImport} from 'tabler-icons-react'

const SearchResult = ({data, onOrderSelected}) => {
    return (
        <Card>
            <Title order={3}>Search Results</Title>
            <List>
                {data.map(order => {
                    return <List.Item key={order.id} mt='lg'
                                      icon={<ThemeIcon color={order.order_status === 'New' ? 'red' : 'green'} size={20}>
                                        <DatabaseImport />
                                    </ThemeIcon>}>
                        <Tooltip color='lime' label={<Card>
                            <Table>
                                <tbody>
                                    {order.delivery_name_1 ? <tr>
                                        <td>Name 1:</td>
                                        <td>
                                            <strong>{order.delivery_name_1}</strong>
                                        </td>
                                    </tr> : null}
                                    {order.delivery_name_2 ? <tr>
                                        <td>Name 2:</td>
                                        <td>
                                            <strong>{order.delivery_name_2}</strong>
                                        </td>
                                    </tr> : null}
                                    {order.delivery_contact ? <tr>
                                        <td>Contact</td>
                                        <td>
                                            <strong>{order.delivery_contact}</strong>
                                        </td>
                                    </tr> : null}
                                    {order.reference_1 ? <tr>
                                        <td>Del #</td>
                                        <td>
                                            <strong>{order.reference_1}</strong>
                                        </td>
                                    </tr> : null}
                                    {order.reference_2 ? <tr>
                                        <td>Order #</td>
                                        <td>
                                            <strong>{order.reference_2}</strong>
                                        </td>
                                    </tr> : null}
                                    {order.reference_3 ? <tr>
                                        <td>Web Order #</td>
                                        <td>
                                            <strong>{order.reference_3}</strong>
                                        </td>
                                    </tr> : null}
                                    {order.reference_4 ? <tr>
                                        <td>Buyer Ref</td>
                                        <td>
                                            <strong>{order.reference_4}</strong>
                                        </td>
                                    </tr> : null}
                                </tbody>
                            </Table>
                        </Card>} position='right'>
                            <Anchor
                                onClick={() => {onOrderSelected(order)}}
                                size='sm'>{order.reference_1} {order.delivery_name_1 ? `(${order.delivery_name_1})` : null}</Anchor>
                        </Tooltip>
                    </List.Item>
                })}
            </List>
        </Card>
    )
}

SearchResult.propTypes = {
    data: PropTypes.array.isRequired,
    onOrderSelected: PropTypes.func.isRequired
}

export default SearchResult