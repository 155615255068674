import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {Text, Anchor} from '@mantine/core'
import _ from 'lodash'

const getCustomerCompany = (customers, customerId) => {
    const index = _.findIndex(customers, c => {return c.id === customerId})
    if (index > -1) {
        return customers[index].company
    }
    return ""
}

const HistoryTableLine = ({line, customers, navigate}) => {
    return <tr>
        <td>{line.id}</td>
        <td>{getCustomerCompany(customers, line.customer_id)}</td>
        <td>{line.order ? line.order.delivery_number : ""}</td>
        <td>{line.order ? line.order.order_number : ""}</td>
        <td>{line.order ? line.order.web_order_number : ""}</td>
        <td>{line.reference_1}</td>
        <td>{line.reference_2}</td>
        <td>{line.return_date}</td>
        <td>{line.is_blind ? <Text color='teal'>Y</Text> : <Text color='yellow'>N</Text>}</td>
        <td>{line.username}</td>
        <td>
            <Anchor component={Link} to={`/orders/${line.order ? line.order_id : 'blind'}/returns/${line.id}`}>View</Anchor>
        </td>
    </tr>
}

HistoryTableLine.propTypes = {
    line: PropTypes.object.isRequired,
    customers: PropTypes.array.isRequired,
    navigate: PropTypes.func.isRequired
}

export default HistoryTableLine