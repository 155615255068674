import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {
    TextInput, Box, Group, Button, Divider, Select
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import {
    NEW_CUSTOMER_OPTION_CREATED,
    CUSTOMER_OPTION_UPDATED, CUSTOMER_OPTION_DELETED
} from '../../redux/action'
import api from '../../api'

const OptionForm = ({initialValues,onCloseForm, customer}) => {
    const dispatch = useDispatch()
    const CustomerOptionSchema = Yup.object().shape({
        option_code: Yup.string().required("Code is required"),
        option_value: Yup.string().required("Value is required")
    })

    const form = useForm({
        initialValues: initialValues,
        schema: yupResolver(CustomerOptionSchema)
    })

    const [loading, setLoading] = useState(false)
    return (
        <Box mt={10}>
            <Divider my='lg' size='lg'/>
            <form onSubmit={form.onSubmit(async (values) => {
                setLoading(true)
                let response
                if (values.id === '_new') {
                    response = await api.admin.createOption(customer.id, values)
                } else {
                    response = await api.admin.updateOption(customer.id, values.id, values)
                }
                const {code, message} = response
                if (code === 200) {
                    if (values.id === '_new') {
                        dispatch({type: NEW_CUSTOMER_OPTION_CREATED, payload: {customerId: customer.id, data: message}})
                    } else {
                        dispatch({type: CUSTOMER_OPTION_UPDATED, payload: {
                            customerId: customer.id,
                            optionId: values.id,
                            data: message
                        }})
                    }
                    setLoading(false)
                    onCloseForm()
                } else {
                    setLoading(false)
                }
            })}>
                <TextInput
                    required
                    label="Option Code"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('option_code')}
                />
                <TextInput
                    label="Option Value"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('option_value')}
                />
                <Select mt='lg'
                        data={[{ value: 'Reason', label: 'Reason' }]}
                        label="Option Type"
                        {...form.getInputProps('option_type')}
                />
                <Group position="left" mt="md">
                    <Button type="submit" color='sgl' loading={loading}>Save</Button>
                    <Button variant='subtle' color='sgl' loading={loading} onClick={() => {onCloseForm()}}>Close</Button>
                    {initialValues.id !== '_new' ? <Button variant='subtle' color='sgl' loading={loading} onClick={async () => {
                        setLoading(true)
                        await api.admin.deleteOption(customer.id, initialValues.id)
                        dispatch({type: CUSTOMER_OPTION_DELETED, payload: {
                                customerId: customer.id,
                                optionId: initialValues.id
                            }})
                        setLoading(false)
                        onCloseForm()
                    }}>Delete</Button> : null}
                </Group>
            </form>
        </Box>
    )
}

OptionForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    onCloseForm: PropTypes.func.isRequired
}

export default OptionForm