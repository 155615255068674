import React from 'react'
import PropTypes from 'prop-types'
import { List, ThemeIcon, Text } from '@mantine/core';
import { ServerOff } from 'tabler-icons-react';

const FormErrorMessage = ({errors}) => {
    return (
        <List
            spacing="xs"
            size="sm"
            icon={
                <ThemeIcon color='sgl' size={20} radius="xl">
                    <ServerOff size={14} />
                </ThemeIcon>
            }
            mt="xl"
        >
            {errors.map(e => {
                return <List.Item key={e}>
                    <Text color='sgl' size='sm'>{e}</Text>
                </List.Item>
            })}
        </List>
    )
}

FormErrorMessage.propTypes = {
    errors: PropTypes.array.isRequired
}

export default FormErrorMessage