import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import store from './redux/store'
import AppRoutes from './routes/index'
import reportWebVitals from './reportWebVitals';
import { NotificationsProvider } from '@mantine/notifications';
import './app.sass'
import {
    MantineProvider
} from '@mantine/core'
const SGL_COLOR = "#cd171d"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Provider store={store}>
          <MantineProvider
            theme={{
                colors: {
                    'sgl' : ['#FFEBEE', '#FFCDD2', '#EF9A9A', '#E57373', '#EF5350', '#F44336', SGL_COLOR, '#D32F2F', '#C62828','#B71C1C' ],
                },
                fontFamily: "Roboto"
            }}
          >
              <NotificationsProvider>
                  <AppRoutes />
              </NotificationsProvider>
          </MantineProvider>
      </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
