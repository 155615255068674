import React, {useState, useEffect} from 'react'
import update from 'immutability-helper'
import {
    Title,
    Grid,
    Table,
    Badge,
    Button,
    Pagination,
    Input,
    Select,
    Modal, Anchor
} from '@mantine/core'
import _ from 'lodash'
import api from '../../../api'
import TaskTableLine from "./partials/TaskTableLine";
import TaskModal from "./partials/TaskModal";

const loadTasks = async (page, search, setResponse) => {
    const {message_1, task_status, task_type} = search
    const q = {}
    if (message_1) {
        q["message_1"] = message_1
    }
    if (task_status && task_status !== '__all') {
        q["task_status"] = task_status
    }
    if (task_type && task_type !== '__all') {
        q["task_type"] = task_type
    }
    const {code, message} = await api.admin.getSystemTasks(page, _.isEmpty(q) ? null : new URLSearchParams(q).toString())
    if (code === 200) {
        _.each(message.data, r => {
            if (_.isNull(r.message_2)) {
                r.message_2 = ""
            }
        })
        setResponse(message)
        return true
    }
    return false
}

const Task = () => {
    const [task, setTask] = useState({id: null})
    const [query, setQuery] = useState({
        message_1: "",
        task_status: "__all",
        task_type: "__all"
    })
    const [response, setResponse] = useState({
        data: {},
        on_page: 1,
        per_page: 50,
        total_records: 0,
        total_pages: 0
    })
    useEffect(() => {
        loadTasks(1, query, setResponse)
    }, [])
    return (
        <Grid grow={true} size='xl'>
            <Grid.Col span={12}>
                <Title order={3}>System Task Status</Title>
                <Table mt="lg">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Message 1</th>
                            <th>Message 2</th>
                            <th>In Queue</th>
                            <th>Attempts</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Created</th>
                            <th>Detail</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>
                                <Input size='xs' variant='filled' value={query.message_1} onChange={e => {
                                    setQuery(update(query, {
                                        message_1: {$set: e.currentTarget.value}
                                    }))
                                }}  />
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>
                                <Select size='xs' variant='filled' data={[
                                    {value: '__all', label: 'All'},
                                    {value: 'Pending', label: 'Pending'},
                                    {value: 'Success', label: 'Success'},
                                    {value: 'Error', label: 'Error'},
                                    {value: 'Log Only', label: 'Log'},
                                    {value: 'Before Downloading', label: 'Downloading'},
                                ]} value={query.task_status} onChange={e => {
                                    setQuery(update(query, {
                                        task_status: {$set: e}
                                    }))
                                }} />
                            </th>
                            <th>
                                <Select size='xs' variant='filled' data={[
                                    {value: '__all', label: 'All'},
                                    {value: 'SGL Inbound', label: 'SGL Inbound'},
                                    {value: 'SGL Outbound', label: 'SGL Outbound'},
                                    {value: 'FGL Outbound', label: 'FGL Outbound'},
                                    {value: 'System Log', label: 'System Log'}
                                ]} value={query.task_type} onChange={e => {
                                    setQuery(update(query, {
                                        task_type: {$set: e}
                                    }))
                                }} />
                            </th>
                            <th></th>
                            <th>
                                <Button color='sgl'
                                        onClick={() => {
                                            loadTasks(1, query, setResponse)
                                        }}
                                        variant='subtle' compact={true}>Search</Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {_.isArray(response.data) ? response.data.map(task => {
                        return <TaskTableLine task={task} setTask={(t) => {setTask(t)}} key={task.id}/>
                    }) : null}
                    <tr>
                        <td colSpan={9}>
                            <Pagination color='sgl' page={response.on_page} onChange={(v) => {
                                loadTasks(v, query, setResponse)
                            }} total={response.total_pages} />
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Grid.Col>
            <TaskModal task={task} setTask={(t) => {setTask(t)}} taskUpdated={(t) => {
                const taskIndex = _.findIndex(response.data, item => {
                    return item.id === t.id
                })
                if (taskIndex > -1) {
                    setResponse(update(response, {
                        data: {
                            [taskIndex]: {$set: t}
                        }
                    }))
                }
                setTask({id: null})
            }} />
            {/*<Modal*/}
            {/*    opened={!!task.id}*/}
            {/*    onClose={() => {setTask({id: null})}}*/}
            {/*    title="Task Detail"*/}
            {/*    size={800}*/}
            {/*    closeOnClickOutside={false}*/}
            {/*>*/}
            {/*    <Grid grow={true}>*/}
            {/*        <Grid.Col span={6}>*/}
            {/*            <Title order={6}>Task Logs</Title>*/}
            {/*            <Anchor onClick={async () => {*/}
            {/*                const {code, message} = await api.admin.getTaskFileUrl(task.id)*/}
            {/*                if (code === 200) {*/}

            {/*                }*/}
            {/*                console.log(message)*/}
            {/*            }} mt='xl'>download file</Anchor>*/}
            {/*            <ReactJson src={_.isObject(task.details) ? task.details : {}} />*/}
            {/*        </Grid.Col>*/}
            {/*        <Grid.Col span={6}>*/}
            {/*            <Title order={6}>Task Action</Title>*/}
            {/*            {task.id ? <TaskForm initialValues={task} taskUpdated={(t) => {*/}
            {/*                const taskIndex = _.findIndex(response.data, item => {*/}
            {/*                    return item.id === t.id*/}
            {/*                })*/}
            {/*                if (taskIndex > -1) {*/}
            {/*                    setResponse(update(response, {*/}
            {/*                        data: {*/}
            {/*                            [taskIndex]: {$set: t}*/}
            {/*                        }*/}
            {/*                    }))*/}
            {/*                }*/}
            {/*                setTask({id: null})*/}
            {/*            }} /> : null}*/}
            {/*        </Grid.Col>*/}
            {/*    </Grid>*/}
            {/*</Modal>*/}
        </Grid>
    )
}

export default Task