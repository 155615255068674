import React, {useState, useEffect} from 'react'
import {Anchor, Title, Alert, Loader} from '@mantine/core'
import {AlertCircle} from 'tabler-icons-react'
import {Link, useParams} from 'react-router-dom'
import api from '../../../api'
import ResetPasswordForm from '../../forms/ResetPasswordForm'


const LOGO = require('../../../assets/scan_logo_1.png')
const ResetPassword = () => {
    const [tokenVerified, setTokenVerified] = useState(false)
    const [loading, setLoading] = useState(true)
    const {username, token} = useParams()
    useEffect(() => {
        const verifyToken = async () => {
            const {code} = await api.auth.verifyToken(username, token)
            if (code === 200) {
                setLoading(false)
                setTokenVerified(true)
            }
        }
        verifyToken()
    }, [token, username])
    return (
        <div className="auth-container">
            <div className="auth">
                <div className="auth-brand-logo">
                    <img alt="SGL Logo" src={LOGO} />
                </div>
                <Title order={3}>Reset Password</Title>
                {loading ? <Loader color='red' /> : <div className="auth-form">
                    {tokenVerified ? <ResetPasswordForm username={username} token={token} /> : <Alert icon={<AlertCircle size={16} />} title="Expired Link" color="red">
                        Your reset link seems to be expired, please submit another request. <br />
                        <Anchor size='sm' component={Link} to="/auth/forget-password">
                            Reset Password
                        </Anchor>
                    </Alert>}
                </div>}
                <div className="auth-link">
                    <Anchor size='sm' component={Link} to="/auth/login">
                        Back to login
                    </Anchor>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword