import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const calculateReturned = (line_id, ledgers = [], condition = []) => {
    let qty = 0
    ledgers.map(l => {
        if (_.isEmpty(condition)) {
            if (l.order_line_id === line_id) {
                qty += l.quantity
            }
        } else {
            if (l.order_line_id === line_id && condition.includes(l.condition)) {
                qty += l.quantity
            }
        }
    })
    return qty
}

const CheckTableLine = ({line, oldLedgers, newLedgers}) => {
    const warningStyle = {}
    if (calculateReturned(line.id, oldLedgers) + calculateReturned(line.id, newLedgers) !== line.quantity) {
        warningStyle["color"] = 'red'
    }
    return (
        <tr style={warningStyle}>
            <td>{line.product.sku}</td>
            <td>{line.product.upc}</td>
            <td style={{maxWidth: 150}}>{line.product.name_1}</td>
            <td>{line.quantity}</td>
            <td>{calculateReturned(line.id, oldLedgers)}</td>
            <td>{calculateReturned(line.id, newLedgers, ['Pristine'])}</td>
            <td>{calculateReturned(line.id, newLedgers, ['Reconditioning', 'Scrap', 'Secondhand'])}</td>
        </tr>
    )
}

CheckTableLine.propTypes = {
    line: PropTypes.shape({
        product: PropTypes.object.isRequired
    }).isRequired,
    oldLedgers: PropTypes.array.isRequired,
    newLedgers: PropTypes.array.isRequired
}

export default CheckTableLine