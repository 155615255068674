import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {
    TextInput, Box, Group, Button, Title, PasswordInput, Checkbox, Select
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import {NEW_CUSTOMER_CREATED, CUSTOMER_UPDATED} from '../../redux/action'
import api from '../../api'

const CustomerForm = ({initialValues, onCloseDraw}) => {
    const dispatch = useDispatch()
    const CustomerFormSchema = Yup.object().shape({
        company: Yup.string().required("Company is required")
    })

    const form = useForm({
        initialValues: initialValues,
        schema: yupResolver(CustomerFormSchema)
    })

    const [loading, setLoading] = useState(false)
    return (
        <Box mx={10}>
            <Title order={3}>Customer Form {initialValues.id === '_new' ? "(New)" : `(${initialValues.company})`}</Title>
            <form onSubmit={form.onSubmit(async (values) => {
                setLoading(true)
                let response
                if (values.id === '_new') {
                    response = await api.admin.createCustomer(values)
                } else {
                    response = await api.admin.updateCustomer(values.id, values)
                }
                const {code, message} = response
                if (code === 200) {
                    if (values.id === '_new') {
                        dispatch({type: NEW_CUSTOMER_CREATED, payload: message})
                    } else {
                        dispatch({type: CUSTOMER_UPDATED, payload: message})
                    }
                    setLoading(false)
                    onCloseDraw()
                } else {
                    setLoading(false)
                }
            })}>
                <TextInput
                    required
                    label="Company"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('company')}
                />
                <Checkbox label="Enable blind receiving"
                          mt="lg"
                          {...form.getInputProps('can_blind', {type: 'checkbox'})}
                />
                <Select mt='lg'
                        data={[{ value: 'Active', label: 'Active' },{ value: 'Disabled', label: 'Disabled' }]}
                        label="Account Status"
                        {...form.getInputProps('customer_status')}
                />
                <Select mt='lg'
                        data={[{ value: 'Biztalk', label: 'Biztalk' },{ value: 'FGL V2', label: 'FGL V2' }]}
                        label="Order Source"
                        {...form.getInputProps('order_source')}
                />
                <TextInput
                    label="Image CDN 1"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('cdn_url_1')}
                />
                <TextInput
                    label="Image CDN 2"
                    placeholder=""
                    mt="lg"
                    {...form.getInputProps('cdn_url_2')}
                />
                <Group position="left" mt="md">
                    <Button type="submit" color='sgl' loading={loading}>Save</Button>
                </Group>
            </form>
        </Box>
    )
}

CustomerForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    onCloseDraw: PropTypes.func.isRequired
}

export default CustomerForm