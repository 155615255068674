import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {Table, Button, Box, Title, Badge} from '@mantine/core'
import _ from 'lodash'

const CustomerTable = ({onEditCustomerClick}) => {
    const customers = useSelector(state => state.app.customers)
    const sortedCustomers = _.sortBy(customers, function(customer) {
        return customer.company
    })
    return (
        <Box mt={0}>
            <Title order={3}>List of Customers</Title>
            <Table captionSide="bottom" mt={20}>
                <caption>
                    <Button color='sgl' variant='subtle' onClick={() => {onEditCustomerClick(null)}}>Add New Customer</Button>
                </caption>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Company</th>
                    <th>Blind Enabled</th>
                    <th>Acct Status</th>
                    <th>Order Source</th>
                    <th>Options</th>
                    <th>Connections</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {sortedCustomers.map(u => {
                    return <tr key={u.id}>
                        <td><Badge color='gray'>{u.id}</Badge></td>
                        <td>{u.company}</td>
                        <td>
                            {u.can_blind ? <Badge color='green'>Y</Badge> : <Badge color='red'>N</Badge>}
                        </td>
                        <td>
                            {u.customer_status === 'Active' ? <Badge color='green'>Active</Badge> : <Badge color='gray'>Disabled</Badge>}
                        </td>
                        <td>
                            <Badge color='gray'>{u.order_source}</Badge>
                        </td>
                        <td>
                            {u.options && u.options.length > 0 ?
                                <Badge color='gray'>{u.options.length}</Badge> :
                                <Badge color='gray'>No Reasons</Badge> }
                        </td>
                        <td>
                            {u.connections && u.connections.length > 0 ?
                                <Badge color='gray'>{u.connections.length} Connection</Badge> :
                                <Badge color='gray'>No Connections</Badge> }
                        </td>
                        <td>
                            <Button color='sgl' variant='subtle' compact={true} onClick={() => {onEditCustomerClick('edit', u)}}>Edit</Button>
                            <Button color='sgl' variant='subtle' compact={true} onClick={() => {onEditCustomerClick('option', u)}}>Options</Button>
                            <Button color='sgl' variant='subtle' compact={true} onClick={() => {onEditCustomerClick('connection', u)}}>
                                Connection
                            </Button>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
        </Box>
    )
}

CustomerTable.propTypes = {
    onEditCustomerClick: PropTypes.func.isRequired
}

export default CustomerTable