import React from 'react'
import PropTypes from 'prop-types'
import {List, Text} from '@mantine/core'

const ApiLoadingError = ({errors}) => {
    return (
        <List>
            {errors.map(e => {
                return <List.Item key={e}>
                    <Text color='sgl' size='sm'>{e}</Text>
                </List.Item>
            })}
        </List>
    )
}

ApiLoadingError.propTypes = {
    errors: PropTypes.array.isRequired
}

export default ApiLoadingError