import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {
    Header,
    Anchor,
    Menu, Text, Group, Modal
} from '@mantine/core'
import { TruckReturn, LayoutDashboard, Settings, UserCircle } from 'tabler-icons-react';
import sys from '../../sys'
import {USER_LOGGED_OUT} from '../../redux/action'
import PasswordForm from '../forms/PasswordForm'

const LOGO_IMG = require("../../assets/scan_logo_1.png")

const TopNav = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [passwordModal, setPasswordModal] = useState(false)
    const user = useSelector((state) => state.app.account)
    return (
        <Header height={60} p="xs">
            <div style={{display: 'flex', height: '100%', flexDirection: "row"}}>
                <div style={{ display: 'flex', flex: 1, alignItems: 'center', height: '100%' }}>
                    <img alt="SGL Logo" src={LOGO_IMG} style={{height: "36px"}}/>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Anchor component={Link} to="/" color={location.pathname === '/' || location.pathname.startsWith('/blind') ? 'red' : 'dark'} mr="md" underline={false}>
                        <Group spacing={1}>
                            <TruckReturn size={22} />
                            <Text>Receiving</Text>
                        </Group>
                    </Anchor>
                    <Anchor component={Link} to="/history?page=1&search%5Bid%5D=&search%5Bdelivery_number%5D=&search%5Border_number%5D=&search%5Bweb_order_number%5D=&filter%5Bcustomer_id%5D=All&filter%5Bis_blind%5D=All"
                            color={location.pathname.startsWith("/history") || location.pathname.startsWith('/orders') ? 'red' : 'dark'} align="center" mr="md" underline={false}>
                        <Group spacing={1}>
                            <LayoutDashboard size={22} />
                            <Text>History</Text>
                        </Group>
                    </Anchor>
                    {user.is_su ? <Menu control={<Anchor color={location.pathname.startsWith("/admin") ? 'red' : 'dark'}
                                                         style={{display: 'flex'}}
                                                         mr="md" underline={false}>
                        <Group spacing={1}>
                            <Settings size={22} />
                            <Text>Admin</Text>
                        </Group>
                    </Anchor>}>
                        <Menu.Item onClick={() => {
                            navigate('/admin/users', {replace: false})
                        }}>
                            <Anchor component={Link} to="/admin/users" color={location.pathname.startsWith("/admin/users") ? 'red' : 'dark'} underline={false}>
                                Users
                            </Anchor>
                        </Menu.Item>
                        <Menu.Item onClick={() => {
                            navigate('/admin/customers', {replace: false})
                        }}>
                            <Anchor component={Link} to="/admin/customers" color={location.pathname.startsWith("/admin/customers") ? 'red' : 'dark'} underline={false}>
                                Customers
                            </Anchor>
                        </Menu.Item>
                        <Menu.Item onClick={() => {
                            navigate('/admin/systems', {replace: false})
                        }}>
                            <Anchor component={Link} to="/admin/systems" color={location.pathname.startsWith("/admin/systems") ? 'red' : 'dark'} underline={false}>
                                System Settings
                            </Anchor>
                        </Menu.Item>
                        <Menu.Item onClick={() => {
                            navigate('/admin/tasks', {replace: false})
                        }}>
                            <Anchor component={Link} to="/admin/tasks" color={location.pathname.startsWith("/admin/tasks") ? 'red' : 'dark'} underline={false}>
                                Task Status
                            </Anchor>
                        </Menu.Item>
                    </Menu> : null }
                    <Menu control={<Anchor color="dark" mr="md" style={{display: 'flex'}} underline={false}>
                        <Group spacing={1}>
                            <UserCircle size={22} />
                            <Text>{user.name}</Text>
                        </Group>
                    </Anchor>}>
                        <Menu.Item onClick={() => {setPasswordModal(true)}}>
                            Change Password
                        </Menu.Item>
                        <Menu.Item onClick={() => {
                            sys.token.clearToken()
                            dispatch({type: USER_LOGGED_OUT, payload: null})
                            navigate('/auth/login', {
                                replace: true
                            })
                        }}>
                            Logout
                        </Menu.Item>
                    </Menu>
                </div>
                <Modal opened={passwordModal}
                       closeOnClickOutside={false}
                       onClose={() => {setPasswordModal(false)}} title="Change Password">
                    <PasswordForm />
                </Modal>
            </div>
        </Header>
    )
}

export default TopNav