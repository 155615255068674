import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
    Grid, Image, Collapse, Group, Text, Button, Table, Tooltip, Stack
} from '@mantine/core'
import { CaretDown, CaretUp, Trash } from 'tabler-icons-react';
import PropTypes from 'prop-types'
import LedgerForm from '../../../forms/LedgerForm'
import {ADD_NEW_LEDGER_TO_RETURN, DELETE_RETURN_LEDGER} from '../../../../redux/action'

const totalReceived = (ledgers = []) => {
    let qty = 0
    ledgers.map(l => {
        qty += l.quantity
    })
    return qty
}

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return '__' + result;
}

const getImageSrc = (cdn1, cdn2, sku) => {
    if (cdn2 === 'VIKINGS') {
        const skuArray = sku.split("-")
        if (skuArray.length > 0) {
            skuArray[skuArray.length - 1] = "a"
            console.log(`${cdn1}/${skuArray.join("-")}.jpg`)
            return `${cdn1}/${skuArray.join("-")}.jpg`
        }
    }

    return ""
}

const ProcessingOrderLine = ({line, customerReasons, ledgers, customerId, cdn1, cdn2}) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(true)
    const newReturnLedgers = useSelector(state => state.app.returnBeingProcessed.return_ledgers)
    const [initForm, setInitForm] = useState({
        quantity: 1,
        condition: 'Pristine',
        reason: customerReasons[0] ? customerReasons[0].value : "",
        comment: ""
    })
    const lineLedgers = []
    ledgers.map(l => {
        if (l.order_line_id === line.id) {
            lineLedgers.push(l)
        }
    })
    newReturnLedgers.map(l => {
        if (l.order_line_id === line.id) {
            lineLedgers.push(l)
        }
    })

    return (
        <Grid mt='xl'>
            <Grid.Col span={10}>
                <Stack spacing={0}>
                    <Group spacing='md'>
                        <Text color='sgl'>{line.product.upc} {line.product.sku ? `(${line.product.sku})` : null}</Text>
                    </Group>
                    <Group spacing='md'>
                        <Text color='gray' size='sm'>Color: {line.product.color}</Text>
                        <Text color='gray' size='sm'>Size: {line.product.size}</Text>
                    </Group>
                </Stack>
            </Grid.Col>
            <Grid.Col span={1}>
                <Group>
                    {totalReceived(lineLedgers) < line.quantity ? <Text weight={700} color='gray'>
                        {totalReceived(lineLedgers)}
                    </Text> : null}
                    {totalReceived(lineLedgers) === line.quantity ? <Text color='green'>
                        {totalReceived(lineLedgers)}
                    </Text> : null}
                    {totalReceived(lineLedgers) > line.quantity ? <Text color='red'>
                        {totalReceived(lineLedgers)}
                    </Text> : null}
                    <Text color='gray'>
                        {`| ${line.quantity}`}
                    </Text>
                </Group>
            </Grid.Col>
            <Grid.Col span={1}>
                <Button color='sgl' compact={true} variant='subtle' onClick={() => {setOpen(!open)}} size='xs'>
                    {open ? <CaretUp /> : <CaretDown />}
                </Button>
            </Grid.Col>
            <Grid.Col span={12}>
                <Collapse in={open}>
                    <Grid>
                        <Grid.Col span={2}>
                            <Image width={150} height={150} src={getImageSrc(cdn1, cdn2, line.product.sku)}
                                   caption={line.product.name_1}
                                   alt="Product Image" withPlaceholder={true}/>
                        </Grid.Col>
                        <Grid.Col span={10}>
                            <Table>
                                <tbody>
                                <tr>
                                    <td colSpan={6}>
                                        <LedgerForm initialValues={initForm}
                                                    customerReasons={customerReasons}
                                                    onAddNewLedgerClick={(values) => {
                                                        dispatch({
                                                            type: ADD_NEW_LEDGER_TO_RETURN,
                                                            payload: {
                                                                id: makeid(6),
                                                                customer_id: customerId,
                                                                order_id: line.order_id,
                                                                order_line_id: line.id,
                                                                product_id: line.product_id,
                                                                return_id: null,
                                                                condition: values.condition,
                                                                quantity: values.quantity,
                                                                reason: values.reason,
                                                                comment: values.comment
                                                            }
                                                        })
                                        }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Return ID</td>
                                    <td>Qty</td>
                                    <td>Reason</td>
                                    <td>Condition</td>
                                    <td>Comment</td>
                                    <td>
                                        <Trash size={16} />
                                    </td>
                                </tr>
                                {lineLedgers.map((l,index) => {
                                    return <tr key={index}>
                                        <td>
                                            <Tooltip label={l.return_id ? 'Previous Return' : 'This Return'} withArrow>
                                                {l.return_id ? l.return_id : 'N'}
                                            </Tooltip>

                                        </td>
                                        <td>{l.quantity}</td>
                                        <td>{l.reason}</td>
                                        <td>{l.condition}</td>
                                        <td>{l.comment}</td>
                                        <td>
                                            {!l.return_id ? <Button onClick={async () => {
                                                dispatch({
                                                    type: DELETE_RETURN_LEDGER,
                                                    payload: {
                                                        ledger_id: l.id,
                                                        return_id: l.return_id
                                                    }
                                                })
                                            }} variant='subtle' size='xs' color='sgl'>
                                                <Trash size={16} />
                                            </Button> : null}
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </Table>
                        </Grid.Col>
                    </Grid>
                </Collapse>
            </Grid.Col>
        </Grid>
    )
}

ProcessingOrderLine.propTypes = {
    line: PropTypes.shape({
        id: PropTypes.number.isRequired,
        product: PropTypes.object.isRequired
    }).isRequired,
    cdn1: PropTypes.string,
    cdn2: PropTypes.string,
    ledgers: PropTypes.array.isRequired,
    customerId: PropTypes.number.isRequired,
    customerReasons: PropTypes.array.isRequired
}

export default ProcessingOrderLine