import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import {Grid, Title, Button} from '@mantine/core'
import api from "../../../api";
import _ from "lodash";
import ReturnViewHeader from "./partials/ReturnViewHeader";
import ReturnViewTable from "./partials/ReturnViewTable";
import ReturnViewTimeline from "./partials/ReturnViewTimeline";
import TaskModal from "../settings/partials/TaskModal";
import update from "immutability-helper";

const HistoryTableLine = ({}) => {
    const customers = useSelector(state => state.app.customers)
    const [model, setModel] = useState(null)
    const [task, setTask] = useState({id: null})
    const [loading, setLoading] = useState(false)
    const [customer, setCustomer] = useState({})
    const {order_id, return_id} = useParams()
    useEffect(() => {
        const loadModel = async () => {
            setLoading(true)
            const {code, message} = await api.returns.readReturn(order_id, return_id)
            if (code === 200) {
                setModel(message)
                setCustomer({})
                const customerIndex = _.findIndex(customers, cus => {
                    return cus.id === message.customer_id
                })
                if (customerIndex > -1) {
                    setCustomer(customers[customerIndex])
                }
            } else {
                setModel(false)
            }
            setLoading(false)
        }
        loadModel()
    }, [order_id, return_id])
    const navigate = useNavigate()
    return <Grid grow>
        <Grid.Col span={9}>
            <Title order={3}>Return View</Title>
        </Grid.Col>
        <Grid.Col span={3}>
            {order_id === 'blind' && model && model.id ? <Button onClick={() => {
                navigate(`/blind/${model.customer_id}`)
            }} size='xs' color='sgl'>Create Another</Button> : ''}
        </Grid.Col>
        {model && !loading ? <Grid.Col span={12}>
            <Grid grow>
                <Grid.Col span={9}>
                    <ReturnViewHeader model={model} customer={customer} />
                    <ReturnViewTable model={model} />
                </Grid.Col>
                <Grid.Col span={3}>
                    <ReturnViewTimeline model={model} onViewTaskClick={(task) => {
                        setTask(task)
                    }}/>
                </Grid.Col>
            </Grid>
            <TaskModal task={task} setTask={(t) => {setTask(t)}} taskUpdated={(t) => {
                const taskIndex = _.findIndex(model.tasks, item => {
                    return item.id === t.id
                })
                if (taskIndex > -1) {
                    setModel(update(model, {
                        tasks: {
                            [taskIndex]: {$set: t}
                        }
                    }))
                }
                setTask({id: null})
            }} />
        </Grid.Col> : null}
    </Grid>
}

export default HistoryTableLine