import React, {useState} from 'react'
import {useParams, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import ProductSearch from "../../utilities/ProductSearch";
import _ from 'lodash'
import {
    Alert,
    Grid, Title, TextInput, Table, Select, NumberInput, ThemeIcon, Button
} from '@mantine/core'
import update from "immutability-helper";
import { Trash } from 'tabler-icons-react';
import dayjs from "dayjs";
import api from "../../../api";

const BlindReceiving = () => {
    const navigate = useNavigate()
    const {customer_id} = useParams()
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        reference_1: "",
        reference_2: "",
        return_date: dayjs().format("YYYY-MM-DD"),
        is_blind: true,
        return_ledgers: [

        ]
    })
    const customer = useSelector((state) => {
        const index = _.findIndex(state.app.customers, c => {
            return c.id === parseInt(customer_id)
        })
        if (index > -1) {
            return state.app.customers[index]
        }
        return false
    })
    const options = []
    customer.options.map(o => {
        options.push({
            value: o.option_code,
            label: o.option_value
        })
    })

    return (
        <Grid grow>
            {customer ? <Grid.Col span={12}>
                <Title order={3}>{customer.company} : Blind Return</Title>
            </Grid.Col> : <Grid.Col span={12}>
                <Alert title='Do not proceed' color='red'>
                    Invalid customer account, please do NOT proceed and contact support.
                </Alert>
            </Grid.Col> }
            <Grid.Col span={3}>
                <TextInput label="Reference 1"
                           required
                           onChange={(e) => {
                               setForm(update(form, {
                                   reference_1: {$set: e.currentTarget.value}
                               }))
                           }}
                           value={form.reference_1}
                />
                <TextInput label="Reference 2"
                           mt='lg'
                           onChange={(e) => {
                               setForm(update(form, {
                                   reference_2: {$set: e.currentTarget.value}
                               }))
                           }}
                           value={form.reference_2}
                />
                <TextInput label="Return Date"
                           mt='lg'
                           type='date'
                           onChange={(e) => {
                               setForm(update(form, {
                                   return_date: {$set: e.currentTarget.value}
                               }))
                           }}
                           value={form.return_date}
                />
                <Button color='sg' mt='lg' loading={loading} onClick={async () => {
                    //WE redirect to this page: /orders/blind/returns/:return_id
                    if (form.return_ledgers.length === 0) {
                        return null
                    }
                    if (!form.reference_1) {
                        return null
                    }
                    setLoading(true)
                    const {code, message} = await api.returns.submitBlind(customer_id, form)
                    if (code === 200) {
                        setLoading(false)
                        navigate(message.to, {
                            replace: true
                        })
                    } else {
                        setLoading(false)
                    }

                }}>Save Return</Button>
            </Grid.Col>
            <Grid.Col span={9}>
                <Table>
                    <thead>
                    <tr>
                        <th width={50}>ID</th>
                        <th>SKU</th>
                        <th>UPC</th>
                        <th>Name</th>
                        <th>Color | Size</th>
                        <th width={100}>Condition</th>
                        <th width={100}>Quantity</th>
                        <th>Reason</th>
                        <th>Comment</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th colSpan={9}>
                            <ProductSearch customerId={customer_id} onProductSelected={(product) => {
                                if (_.isObject(product) && _.toString(product.customer_id) === customer_id) {
                                    setForm(update(form, {
                                        return_ledgers: {
                                            $push: [{
                                                product_id: product.id,
                                                _product: product,
                                                condition: 'Pristine',
                                                quantity: 1,
                                                reason: customer.options.length > 0 ? customer.options[0].option_code : "",
                                                comment: ''
                                            }]
                                        }
                                    }))
                                }
                            }}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {form.return_ledgers.map((ledger, index) => {
                        return <tr key={index}>
                            <td>
                                {ledger.product_id}
                            </td>
                            <td>{ledger._product ? ledger._product.sku : null}</td>
                            <td>{ledger._product ? ledger._product.upc : null}</td>
                            <td>{ledger._product ? ledger._product.name_1 : null}</td>
                            <td>{ledger._product ? ledger._product.color : null} | {ledger._product ? ledger._product.size : null}</td>
                            <td>
                                <Select data={[
                                    {label: 'Pristine', value: 'Pristine'},
                                    {label: 'Reconditioning', value: 'Reconditioning'},
                                    {label: 'Scrap', value: 'Scrap'}
                                ]} value={ledger.condition}
                                        onChange={(v) => {
                                            setForm(update(form, {
                                                return_ledgers: {
                                                    [index]: {
                                                        condition: {$set: v}
                                                    }
                                                }
                                            }))
                                        }} size='xs' />
                            </td>
                            <td>
                                <NumberInput size='xs' min={1} value={ledger.quantity} onChange={(e) => {
                                    setForm(update(form, {
                                        return_ledgers: {
                                            [index]: {
                                                quantity: {$set: e}
                                            }
                                        }
                                    }))
                                }} />
                            </td>
                            <td>
                                <Select data={options} value={ledger.reason}
                                        onChange={(v) => {
                                            setForm(update(form, {
                                                return_ledgers: {
                                                    [index]: {
                                                        reason: {$set: v}
                                                    }
                                                }
                                            }))
                                        }} size='xs' />
                            </td>
                            <td>
                                <TextInput size='xs' value={ledger.comment} onChange={(e) => {
                                    setForm(update(form, {
                                        return_ledgers: {
                                            [index]: {
                                                comment: {$set: e.currentTarget.value}
                                            }
                                        }
                                    }))
                                }} />
                            </td>
                            <td>
                                <ThemeIcon color='sgl' style={{cursor: 'pointer'}} onClick={() => {
                                    setForm(update(form, {
                                        return_ledgers: {$splice: [[index, 1]]}
                                    }))
                                }}>
                                    <Trash size={14} />
                                </ThemeIcon>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </Grid.Col>
        </Grid>
    )
}

BlindReceiving.propTypes = {

}

export default BlindReceiving