import React from "react";
import PropTypes from "prop-types";
import {Grid} from "@mantine/core";

const ReturnViewHeader = ({model, customer}) => {
    return <Grid grow>
        <Grid.Col span={4}>
            <div className="app-dl-dt-display">
                <dl>
                    <dt>Return #</dt>
                    <dd>{model.id}</dd>
                </dl>
                <dl>
                    <dt>Ref 1</dt>
                    <dd>{model.reference_1}</dd>
                </dl>
                <dl>
                    <dt>Ref 2</dt>
                    <dd>{model.reference_2}</dd>
                </dl>
                <dl>
                    <dt>Date</dt>
                    <dd>{model.return_date}</dd>
                </dl>
                <dl>
                    <dt>Blind</dt>
                    <dd>{model.is_blind ? 'Y' : 'N'}</dd>
                </dl>
            </div>
        </Grid.Col>

        {model.order && model.order.id ? <Grid.Col span={4}>
            <div className="app-dl-dt-display">
                <dl>
                    <dt>Customer</dt>
                    <dd>{customer.company}</dd>
                </dl>
                <dl>
                    <dt>Source</dt>
                    <dd>{model.order.source}</dd>
                </dl>
                <dl>
                    <dt>Msg #</dt>
                    <dd>{model.order.source_id_1}</dd>
                </dl>
                <dl>
                    <dt>Type</dt>
                    <dd>{model.order.order_type}</dd>
                </dl>
                <dl>
                    <dt>Delivery #</dt>
                    <dd>{model.order.delivery_number}</dd>
                </dl>
                <dl>
                    <dt>Order #</dt>
                    <dd>{model.order.order_number}</dd>
                </dl>
            </div>
        </Grid.Col> : <Grid.Col span={4} />}

        {model.order && model.order.id ? <Grid.Col span={4}>
            <div className="app-dl-dt-display">
                <dl>
                    <dt>Name 1</dt>
                    <dd>{model.order.delivery_name_1}</dd>
                </dl>
                <dl>
                    <dt>Address 1</dt>
                    <dd>{model.order.delivery_address_1}</dd>
                </dl>
                <dl>
                    <dt>Address 2</dt>
                    <dd>{model.order.delivery_address_2}</dd>
                </dl>
                <dl>
                    <dt>Address 3</dt>
                    <dd>{model.order.delivery_city} {model.order.delivery_zipcode}</dd>
                </dl>
                <dl>
                    <dt>Address 4</dt>
                    <dd>{model.order.delivery_country}</dd>
                </dl>
            </div>
        </Grid.Col> : <Grid.Col span={4} />}
    </Grid>
}

ReturnViewHeader.propTypes = {
    model: PropTypes.shape({
        order: PropTypes.object.isRequired
    }).isRequired,
    customer: PropTypes.object.isRequired
}

export default ReturnViewHeader