import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from "react-redux";
import * as Yup from 'yup';
import {
    TextInput, Box, Button, Alert, Select
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import _ from 'lodash';
import api from '../../api'

const schema = Yup.object().shape({
    customer_id: Yup.string().required("Customer account is required"),
    date_from: Yup.date()
        .typeError("please enter a valid date")
        .required(),
    date_to: Yup.date()
        .typeError("please enter a valid date")
        .required(),
    email: Yup.string().email()
})
const ReturnReportForm = () => {
    const customers = useSelector(state => state.app.customers)
    const account = useSelector(state => state.app.account)
    const customerOptions = []
    customers.map((cus) => {
        customerOptions.push({
            value: _.toString(cus.id),
            label: cus.company
        })
    })
    const form = useForm({
        initialValues: {
            report_name: "return_report_001",
            customer_id: "",
            date_from: "",
            date_to: "",
            email: account.email
        },
        schema: yupResolver(schema)
    })
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(false)
    return (
        <Box mt={10}>
            <form onSubmit={form.onSubmit(async (values) => {
                setLoading(true)
                setMessage(false)
                const {code,} = await api.report(values)
                if (code === 200) {
                    setMessage(true)
                    setLoading(false)
                    form.reset()
                }
            })}>
                {message && <Alert title="Success" color="green">
                    An email with the report will be sent shortly.
                </Alert>}
                <Select mt='sm'
                        data={customerOptions}
                        label="Customer Account"
                        size='sm'
                        {...form.getInputProps('customer_id')}
                />

                <TextInput
                    label="Date From"
                    placeholder=""
                    mt="sm"
                    size='sm'
                    type='date'
                    {...form.getInputProps('date_from')}
                />

                <TextInput
                    label="Date To"
                    placeholder=""
                    mt="sm"
                    size='sm'
                    type='date'
                    {...form.getInputProps('date_to')}
                />

                <TextInput
                    label="Send To Email"
                    placeholder=""
                    mt="sm"
                    size='sm'
                    {...form.getInputProps('email')}
                />

                <Button type="submit" color='sgl' mt='lg' loading={loading}>Generate</Button>
            </form>

        </Box>
    )
}

export default ReturnReportForm