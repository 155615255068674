import React, {useState, useEffect} from 'react'
import {
    Button,
    Card,
    Grid, Group, Title, Modal, Table
} from '@mantine/core'
import api from '../../../api'
import {useDispatch, useSelector} from 'react-redux'
import { showNotification } from '@mantine/notifications';
import OrderSearch from './partials/OrderSearch'
import SearchResult from './partials/SearchResult'
import {
    SET_ORDER_IN_STORE_FOR_PROCESSING,
    CLEAR_ORDER_IN_STORE_FOR_PROCESSING,
    NEW_RETURN_CREATED_VIA_API
} from '../../../redux/action'
import {X} from 'tabler-icons-react'
import _ from 'lodash'
import ReturnProcessing from './partials/ReturnProcessing'
import CheckTableLine from './partials/CheckTableLine'

const Receiving = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const dispatch = useDispatch()
    const isProcessingOrder = useSelector(state => state.app.isProcessingOrder)
    const order = useSelector(state => state.app.order)
    const returnBeingProcessed = useSelector(state => state.app.returnBeingProcessed)
    const [data, setData] = useState([])

    return (
        <Grid grow={true}>
            <Grid.Col span={3}>
                <OrderSearch listOfOrdersLoaded={(data) => {
                    setData(data)
                }}/>
                <SearchResult data={data} onOrderSelected={async (order) => {
                    if (isProcessingOrder) {
                        showNotification({
                            title: "Cannot switch to another return",
                            message: "Please close the return currently being processed first.",
                            color: 'red',
                            icon: <X size={16} />
                        })
                        return null
                    }
                    const {code, message} = await api.returns.getOrderById(order._source, order.id ? order.id : 'new', order._data)
                    if (code === 200) {
                        dispatch({type: SET_ORDER_IN_STORE_FOR_PROCESSING, payload: message})
                    }
                }} />
            </Grid.Col>
            <Grid.Col span={9}>
                {isProcessingOrder && order.id && returnBeingProcessed.id ? <Card>
                    <Grid grow={true}>
                        <Grid.Col span={6}>
                            <Title order={3}>Return Processing</Title>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Group position='right'>
                                <Button color='sgl' onClick={() => {
                                    setModalOpen(true)
                                }}>Submit</Button>
                                <Button color='gray' onClick={() => {
                                    dispatch({type: CLEAR_ORDER_IN_STORE_FOR_PROCESSING, payload: null})
                                }}>Close</Button>
                            </Group>
                        </Grid.Col>
                    </Grid>
                    <ReturnProcessing />
                    <Modal opened={modalOpen}
                           title='Return Processing Summary'
                           padding='lg'
                           closeOnClickOutside={false}
                           size={960}
                           onClose={() => {setModalOpen(false)}}>
                        <Table striped={true} captionSide='bottom'>
                            <caption>
                                <Button
                                    size='xs'
                                    loading={submitting}
                                    onClick={async () => {
                                        if (_.isEmpty(returnBeingProcessed.return_ledgers)) {
                                            return null;
                                        }
                                        setSubmitting(true)
                                        const {code, message} = await api.returns.submitReturn(returnBeingProcessed)
                                        if (code === 200) {
                                            dispatch({
                                                type: NEW_RETURN_CREATED_VIA_API,
                                                payload: message
                                            })
                                        }
                                        setSubmitting(false)
                                        setModalOpen(false)
                                    }}
                                    color='sgl'>Confirm & Submit Return</Button>
                            </caption>
                            <thead>
                            <tr>
                                <th>SKU</th>
                                <th>UPC</th>
                                <th>Name</th>
                                <th>Ordered</th>
                                <th>Previous</th>
                                <th>New Pristine</th>
                                <th>New Blocked</th>
                            </tr>
                            </thead>
                            <tbody>
                            {order.order_lines.map(l => {
                                return <CheckTableLine line={l}
                                                       oldLedgers={order.ledgers}
                                                       newLedgers={returnBeingProcessed.return_ledgers}
                                                       key={l.id}
                                />
                            })}
                            </tbody>
                        </Table>
                    </Modal>
                </Card> : null}
            </Grid.Col>
        </Grid>
    )
}

export default Receiving