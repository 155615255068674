import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {Table, Button, Box, Title, Badge} from '@mantine/core'
import _ from 'lodash'

const UserTable = ({onEditUserClick}) => {
    const users = useSelector(state => state.app.users)
    const sortedUsers = _.sortBy(users, function(user) {
        return user.name
    })
    return (
        <Box mt={0}>
            <Title order={3}>List of Users</Title>
            <Table captionSide="bottom" mt={20}>
                <caption>
                    <Button color='sgl' variant='subtle' onClick={() => {onEditUserClick(null)}}>Add New User</Button>
                </caption>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Login Acct</th>
                    <th>Acct Status</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {sortedUsers.map(u => {
                    return <tr key={u.id}>
                        <td><Badge color='gray'>{u.id}</Badge> {u.is_su ? <Badge color='red'>Admin</Badge> : null}</td>
                        <td>{u.name}</td>
                        <td>{u.email}</td>
                        <td>{u.username}</td>
                        <td>
                            {u.user_status === 'Active' ? <Badge color='green'>Active</Badge> : <Badge color='gray'>Disabled</Badge>}
                        </td>
                        <td>
                            <Button color='sgl' variant='subtle' onClick={() => {onEditUserClick(u)}}>Edit</Button>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
        </Box>
    )
}

UserTable.propTypes = {
    onEditUserClick: PropTypes.func.isRequired
}

export default UserTable